import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    rateTiers: [],
    isLoading: false,
  },

  mutations: {
    SET_RATE_TIERS(state, rateTiers) {
      state.rateTiers = rateTiers;
    },

    ADD_RATE_TIER(state, rateTier) {
      state.rateTiers = state.rateTiers.concat(rateTier);
    },

    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },

    RESET_STATE(state) {
      state.rateTiers = [];
      state.isLoading = false;
    }
  },

  actions: {
    async createRateTier({ commit }, rateTierParams) {
      try {
        const response = await http.post('rate_tiers', {
          rate_tier: rateTierParams.rateTier
        });

        const createdRateTier = response.data.rate_tier;
        commit('ADD_RATE_TIER', createdRateTier);
      }

      catch (error) {
        console.log(error);
      }
    },

    async fetchRateTiers({ commit }) {
      try {
        commit('SET_IS_LOADING', true);
        const response = await http.get('rate_tiers');
        commit('SET_RATE_TIERS', response.data.rate_tiers);
        return response.data.rate_tiers;
      }

      finally {
        commit('SET_IS_LOADING', false);
      }
    }
  }
}
