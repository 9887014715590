import http from '@/services/http';
import events from '@/services/events';

export default {
  namespaced: true,

  state: {
    onboardingUser: null,
    currentUser: null,
    currentUserAccounts: [],
    currentUserSubscriptions: [],
    signInStatus: null,
    signInError: null,
    isExpired: false,
    paymentModalIsOpen: false,
  },

  getters: {
    currentUser(state) {
      return state.currentUser;
    },

    onboardingUser(state) {
      return state.onboardingUser;
    },

    currentUserUuid(state) {
      return state.currentUser.uuid;
    },

    isCurrentUserAdmin(state) {
      if (state.currentUser && state.currentUser.is_admin) {
        return true;
      } else {
        return false;
      }
    },

    isCurrentUserAdminOrReseller(state) {
      if (state.currentUser && (state.currentUser.is_admin || state.currentUser.role_name === 'reseller')) {
        return true;
      } else {
        return false;
      }
    },

    canCreateAccounts(state) {
      return state.currentUser && state.currentUser.can_create_accounts;
    },

    canManageUserActivation(state) {
      return state.currentUser && state.currentUser.can_manage_user_activation;
    },

    canManageAccountActivation(state) {
      return state.currentUser && state.currentUser.can_manage_account_activation;
    },

    canManageSubscriptionActivation(state) {
      return state.currentUser && state.currentUser.can_manage_subscription_activation;
    },

    canCreateSubscriptions(state) {
      return state.currentUser && state.currentUser.can_create_subscriptions;
    },

    canUpdateSubscriptions(state) {
      return state.currentUser && state.currentUser.can_update_subscriptions;
    },

    canManageWatchLists(state) {
      return state.currentUser && state.currentUser.can_manage_watch_lists;
    },

    canCreateTokens(state) {
      return state.currentUser && state.currentUser.can_create_tokens;
    },

    canUpdateTokens(state) {
      return state.currentUser && state.currentUser.can_update_tokens;
    },

    canManageTokenActivation(state) {
      return state.currentUser && state.currentUser.can_manage_token_activation;
    },

    canCreateEndpoints(state) {
      return state.currentUser && state.currentUser.can_create_endpoints;
    },

    canUpdateEndpoints(state) {
      return state.currentUser && state.currentUser.can_update_endpoints;
    },

    canUpdateEndpointValues(state) {
      return state.currentUser && state.currentUser.can_update_endpoint_values;
    },

    canManageEndpointActivation(state) {
      return state.currentUser && state.currentUser.can_manage_endpoint_activation;
    },

    canManageOriginActivation(state) {
      return state.currentUser && state.currentUser.can_manage_origin_activation;
    },

    canDeleteOrigins(state) {
      return state.currentUser && state.currentUser.can_delete_origins;
    },

    canManageAccessTokens(state) {
      return state.currentUser && state.currentUser.can_manage_access_tokens;
    },

    paymentModalIsOpen(state) {
      return state.paymentModalIsOpen;
    },

    currentUserEmail(state) {
      return state.currentUser.email;
    },

    helioPayStreamLink(state) {
      if (state.currentUserAccounts && state.currentUserAccounts.length > 0) {
        const payStreamId = state.currentUserAccounts[0].pay_stream_id;
        const accountUuid = state.currentUserAccounts[0].uuid;
        const encodedEmail = encodeURIComponent(state.currentUser.email);

        return `https://www.hel.io/s/${payStreamId}?email=${encodedEmail}&productValue=${accountUuid}`;
      }
    },

    coinflowAccounts(state) {
      return state.currentUserAccounts.filter(account => account.payment_method === 'coinflow');
    },

    coinflowLink(state) {
      if (state.currentUserAccounts && state.currentUserAccounts.length > 0) {
        const email = encodeURIComponent(state.currentUser.email);
        const accountUuid = state.currentUserAccounts[0].uuid;

        let baseUrl = '';
        let planCode = '';
        if (window.location.hostname === 'customers.triton.one') {
          baseUrl = 'https://triton.coinflow.cash';
          planCode = 'production_starter_plan';
        } else {
          baseUrl = 'https://triton-staging.coinflow.cash';
          planCode = 'basic_plan';
        }

        return `${baseUrl}/subscribe?email=${email}&accountUuid=${accountUuid}&planCode=${planCode}`;
      }
    },

    currentUserHelioAccounts(state) {
      if (state.currentUserAccounts.length) {
        return state.currentUserAccounts.filter(account => account.payment_method === 'helio');
      } else {
        return [];
      }
    },

    currentUserCoinflowAccounts(state) {
      if (state.currentUserAccounts.length) {
        return state.currentUserAccounts.filter(account => account.payment_method === 'coinflow');
      } else {
        return [];
      }
    },

    hasAccountWithHelio(state) {
      return state.currentUserAccounts.some(account => account.payment_method === 'helio');
    },

    hasAccountWithCoinflow(state) {
      return state.currentUserAccounts.some(account => account.payment_method === 'coinflow');
    },

    hasAccountAndSubscription(state) {
      return state.currentUserAccounts.length > 0 && state.currentUserSubscriptions.length > 0;
    },

    isOnboardingUnfinished(state) {
      return state.onboardingUser
        && state.onboardingUser.created_via === 'self_signup'
        && state.onboardingUser.subscriptions.length === 0;
    },
  },

  actions: {
    async initializeSession({ commit, dispatch }) {
      try {
        const response = await dispatch('getSessionUser');
        const user = response.data.user;

        if (user.is_admin) {
          http.post('mattermost_chats/init_chat');
        }

        commit('SET_CURRENT_USER', user);
        commit('SET_SIGN_IN_STATUS', 'success');
        commit('CLEAR_ONBOARDING_USER');
      }
      catch (error) {
        commit('SET_SIGN_IN_ERROR', 'You must sign in to access this page');
        commit('SET_SIGN_IN_STATUS', 'warning');
      }
    },

    async getSessionUser({ commit }) {
      try {
        return await http.get('sessions');
      }
      catch (error) {
        throw error;
      }
    },

    async updateSessionUser({ commit }, userParams) {
      try {
        commit('UPDATE_CURRENT_USER', userParams);
      }
      catch (error) {
        console.log(error);
        throw error;
      }
    },

    async signIn({ commit, dispatch }, { username = '', password = '', loading = true }) {
      try {
        if (loading) { commit('SET_SIGN_IN_STATUS', 'loading'); }

        const response = await http.post('sessions', {
          user: {
            username: username,
            password: password
          }
        });

        const user = response.data.user;

        if (user.is_admin) {
          http.post('mattermost_chats/init_chat');
        }

        if (user.created_via === 'self_signup' && user.subscriptions.length === 0) {
          commit('SET_ONBOARDING_USER', user);
          commit('CLEAR_CURRENT_USER');
        } else {
          commit('SET_SIGN_IN_STATUS', 'success');
          commit('SET_CURRENT_USER', user);
          commit('CLEAR_ONBOARDING_USER');

          events.$emit('authorized');
        }
      }

      catch (error) {
        throw error;
      }
    },

    async signOut({ commit, dispatch }) {
      try {
        dispatch('resetModulesState');
        commit('RESET_STATE');
        commit('SET_SIGN_IN_STATUS', 'signedOut');
        await http.delete('sessions');
      }

      catch (error) {
        console.log(error);
      }
    },

    resetModulesState({ commit }) {
      // Reset all store modules
      const moduleNames = [
        'accounts',
        'subscriptions',
        'subscriptionTypes',
        'marketplaceEpochs',
        'rateTiers',
        'roles',
        'loadBalancers',
        'metrics'
      ];

      moduleNames.forEach(module => commit(`${module}/RESET_STATE`, null, { root: true }));
    },

    unauthorizedAccess({ commit, state, dispatch }) {
      commit('RESET_STATE');
      dispatch('resetModulesState');
      commit('SET_SESSION_EXPIRED');
    },

    togglePaymentModal({ commit }) {
      commit('TOGGLE_PAYMENT_MODAL');
    }
  },

  mutations: {
    SET_CURRENT_USER(state, userObj) {
      state.currentUser = userObj;
      state.currentUserAccounts = userObj.accounts;
      state.currentUserSubscriptions = userObj.subscriptions;
    },

    SET_ONBOARDING_USER(state, userObj) {
      state.onboardingUser = userObj;
    },

    UPDATE_CURRENT_USER(state, userParams) {
      state.currentUser.username = userParams.username;
      state.currentUser.email = userParams.email;
    },

    UPDATE_CURRENT_USER_ACCOUNT(state, params) {
      const account = state.currentUserAccounts.find(account => account.uuid === params.account_uuid);

      if (params.mattermost_channel_guid) {
        account.mattermost_channel_guid = params.mattermost_channel_guid;
      }

      if (account.payment_method === 'helio') {
        account.is_helio_pay_stream_active = params.is_active;
      } else {
        account.is_active = params.is_active;
      }
    },

    UPDATE_CURRENT_USER_ACCOUNT_CHAT(state, params) {
      const account = state.currentUserAccounts.find(account => account.uuid === params.accountUuid);
      account.has_active_mattermost_chat = params.mattermostChatState;
    },

    ADD_ONBOARDING_USER_ACCOUNT(state, accountObj) {
      state.onboardingUser.accounts.push(accountObj)
    },

    UPDATE_ACCOUNTS_SUBSCRIPTIONS(state, params) {
      const subscriptions = state.currentUserSubscriptions.filter(subscription => subscription.account_uuid === params.account_uuid);
      subscriptions.forEach(subscription => {
        subscription.is_active = params.is_active;
      });
    },

    CLEAR_CURRENT_USER(state) {
      state.currentUser = null;
      state.currentUserAccounts = [];
      state.currentUserSubscriptions = [];
    },

    CLEAR_ONBOARDING_USER(state) {
      state.onboardingUser = null;
    },

    SET_SIGN_IN_ERROR(state, message) {
      state.signInError = message;
    },

    SET_SIGN_IN_STATUS(state, status) {
      state.signInStatus = status;
    },

    CLEAR_SIGN_IN_ERROR(state) {
      state.signInError = null;
      state.signInStatus = null;
    },

    RESET_STATE(state) {
      state.onboardingUser = null;
      state.currentUser = null;
      state.currentUserAccounts = [];
      state.currentUserSubscriptions = [];
      state.signInStatus = null;
      state.signInError = null;
      state.isExpired = false;
      state.paymentModalIsOpen = false;
    },

    // a user session has expired and the user is no longer signed in
    SET_SESSION_EXPIRED(state) {
      state.currentUser = null;
      state.currentUserAccounts = [];
      state.currentUserSubscriptions = [];
      state.isExpired = true;
    },

    TOGGLE_PAYMENT_MODAL(state) {
      state.paymentModalIsOpen = !state.paymentModalIsOpen;
    },

    SHOW_PAYMENT_MODAL(state) {
      state.paymentModalIsOpen = true;
    },

    HIDE_PAYMENT_MODAL(state) {
      state.paymentModalIsOpen = false;
    }
  }
}
