<template>
  <div class="p-3 container-xl">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h2>Users</h2>
        <div class="text-center">
          <b-button class="btn-primary" v-b-modal.create-new-user>
            New
          </b-button>

          <create-new-user-modal v-on:user-created="onUserCreated" />
        </div>
      </div>
      <div class="card-body">
        <users-table />
      </div>
    </div>
  </div>
</template>

<script>
import usersTable from '@/components/pages/users/users-table';

export default {
  name: 'AdminUsers',

  components: { usersTable },

  methods: {
    async onUserCreated(user) {
      this.$router.push({
        name: 'manageUserRpc',
        params: { uuid: user.uuid },
      });
    },
  }
}
</script>
