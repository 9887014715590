<template>
  <div>
    <b-navbar>
      <!-- Ignore active state that Vue router provides by setting active-class to "" -->
      <router-link to="/" class="mobile-menu navbar-brand nav-logo" active-class="">
        <triton-logo :height="60" />
      </router-link>

      <b-navbar-nav :class="'w-100 d-flex ' + justifyClass">
        <div v-if="currentUser.is_admin">
          <div class="search-box mt-md-0 mb-md-0 mr-3">
            <b-form-input
              v-on:keyup.enter="search"
              v-model="searchTerm"
              class="form-control search-input"
              placeholder="Search anything..."
              ref="searchInput"
            />
            <b-icon-search class="search-box-icon"></b-icon-search>
            <b-button
              v-if="searchTerm"
              title="Clear Search Input"
              class="clear-search-input close"
              variant="outline-primary"
              @click="clearSearchInput"
            >
              <b-icon class="x-mark" icon="x-lg"></b-icon>
            </b-button>
          </div>
        </div>

        <div class="d-flex">
          <edit-user-modal />

          <div class="top-nav-menu mr-2">
            <div v-if="currentUser?.is_admin" class="icon-wrapper mr-2" v-b-tooltip.hover.bottom title="Customer Support (Mattermost)">
              <a :href="mattermostUrl" target="_blank">
                <b-icon icon="chat-fill" class="main-icon"></b-icon>
              </a>
            </div>

            <div v-if="hasAccountWithHelio" class="icon-wrapper mr-2" v-b-tooltip.hover.bottom title="Payment Docs">
              <a href="https://docs.triton.one/customers/payments" target="_blank">
                <b-icon icon="currency-dollar" class="main-icon"></b-icon>
              </a>
            </div>

            <div class="icon-wrapper mr-2" v-b-tooltip.hover.bottom title="Triton Docs">
              <a href="https://docs.triton.one/" target="_blank">
                <b-icon icon="book-fill" class="main-icon"></b-icon>
              </a>
            </div>

            <div class="icon-wrapper mr-2" v-b-tooltip.hover.bottom title="Email us">
              <a href="mailto:help@triton.one" target="_blank">
                <b-icon icon="life-preserver" class="main-icon"></b-icon>
              </a>
            </div>

            <div class="icon-wrapper mr-2" v-b-tooltip.hover.bottom title="Telegram">
              <a href="https://t.me/joinchat/K0ONdq7fE4s0Mjdl" target="_blank">
                <b-icon icon="telegram" />
              </a>
            </div>
          </div>

          <b-button v-b-toggle="'menu-collapse'" class="mr-2 mobile-menu-button" v-model="isMenuOpen">
            <b-icon :icon="isMenuOpen ? 'x' : 'filter'" />
          </b-button>
        </div>
      </b-navbar-nav>
    </b-navbar>

    <!-- Sidebar links should move up here once the sidebar collapses due to screen width -->
    <b-collapse id="menu-collapse" class="w-100 mobile-menu" v-model="isMenuOpen">
      <div class="links-container mt-2" d-flex>
        <template v-if="currentUser.is_admin">
          <router-link to="/admins/accounts" class="nav-links d-flex align-items-center" exact>
            <b-icon icon="wallet-fill" />
            <span>Accounts</span>
          </router-link>

          <router-link to="/admins/users" class="nav-links d-flex align-items-center" exact>
            <b-icon icon="person-lines-fill" />
            <span>Users</span>
          </router-link>

          <router-link to="/admins/subscriptions" class="nav-links d-flex align-items-center" exact>
            <b-icon icon="file-earmark-medical" />
            <span>Subscriptions</span>
          </router-link>

          <router-link to="/admins" class="nav-links d-flex align-items-center" exact>
            <b-icon icon="speedometer" />
            <span>Admin</span>
          </router-link>

          <router-link to="/admins/marketplace" class="nav-links d-flex align-items-center">
            <b-icon icon="graph-up" class="icon mr-2" />
            <span>(Admin) Cascade Marketplace</span>
          </router-link>

          <router-link to="/admins/reporting" class="nav-links d-flex align-items-center">
            <b-icon icon="bullseye" />
            <span>Reporting</span>
          </router-link>
        </template>

        <router-link
          :to="{
            name: 'manageUserRpc',
            params: { uuid: currentUser.uuid },
          }"
          class="nav-links d-flex align-items-center"
        >
          <b-icon icon="plug-fill" />
          <span>RPC</span>
        </router-link>

        <template v-if="!currentUser.is_admin">
          <div v-for="account in currentUser.accounts" :key="account.uuid">
            <router-link
              :to="{ name: 'editAccount', params: { uuid: account.uuid } }"
              class="nav-links d-flex"
              exact
            >
            <b-icon icon="wallet2" />
            Account:
              <span>{{ account.name }}</span>
            </router-link>
          </div>
        </template>

        <div class="nav-links d-flex align-items-center cursor-pointer" v-b-modal.manage-user-modal>
          <b-icon icon="gear-fill" />
          <span>Manage User</span>
        </div>

        <div class="nav-links d-flex align-items-center cursor-pointer" @click="openSignOutConfirmation">
          <b-icon icon="box-arrow-in-right" />
          <span>Sign Out</span>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import { BIcon, BCollapse, BButton, BIconSearch } from 'bootstrap-vue';

import editUserModal from './edit-user-modal';

export default {
  components: {
    editUserModal, BIcon, BCollapse, BButton, BIconSearch
  },

  data() {
    return {
      searchTerm: null,
      isMenuOpen: false,
    };
  },

  computed: {
    ...mapGetters('sessions', ['currentUser', 'hasAccountWithHelio']),
    ...mapState(['systemFeatures']),

    justifyClass() {
      return this.currentUser.is_admin ? 'justify-content-between' : 'justify-content-end';
    },

    mattermostUrl() {
      return this.systemFeatures?.mattermost_url;
    },
  },

  methods: {
    search() {
      if (this.searchTerm.match(/\S+/)) {
        if (this.$route.path === '/search') {
          this.$store.commit('SET_SEARCH_QUERY', this.searchTerm);
        }

        this.$router.push({
          path: '/search',
          query: { query: this.searchTerm }
        });
      }
    },

    clearSearchInput() {
      this.searchTerm = null;
      this.$refs.searchInput.focus();
    },

    openSignOutConfirmation() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to sign out?', {
          okVariant: 'primary',
          okTitle: 'Yes',
        })
        .then((value) => {
          if (value === true) {
            this.$root.$bvToast.toast(`You successfully signed out`, {
              title: 'Signed out',
              variant: 'default',
              autoHideDelay: 100,
            });
            this.signOut();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async signOut() {
      this.$router.push('/users/sign-in');
      await this.$store.dispatch('sessions/signOut');
    },
  }
}
</script>

<style scoped>
.top-nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

::v-deep .dropdown-menu {
  margin-top: 0.5rem;
  border-radius: 13px;
  padding: 7px;
}
::v-deep .dropdown-item {
  color: #333;
  padding: 10px 20px;
  border-radius: 9px;
}
::v-deep .dropdown-menu::after {
  display: none;
}
::v-deep .dropdown-item:active {
  transform: scale(0.98);
}

.form-control.search-input {
  background-color: #f8f9fa;
  border: 1px solid #c3cad0;
  border-radius: 0.75rem;
  height: 2.3rem;
  transition: all 0.15s ease-in-out;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1);
}

.form-control.search-input:hover {
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.form-control.search-input:focus {
  border-color: #8a91d7;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #cdcfea;
}

.form-control.search-input::placeholder {
  color: #6c757d;
  opacity: 1;
}
.x-mark {
  padding: 2px;
}

button.clear-search-input {
  color: aliceblue;
  top: 45%;
  right: -2.3%;
  background-color: #879cb5;
  border-radius: 8px;
  margin-top: 2px;
  padding: 4px;
}
button.clear-search-input:active,
button.clear-search-input:hover {
  color: aliceblue;
  top: 45%;
  right: -2.3%;
  background-color: #879cb5;
  border-radius: 8px;
  margin-top: 2px;
  padding: 4px;
}

nav {
  display: flex;
  padding: 15px 17px;
  background-color: #e1e7ef;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.mobile-menu-button:hover {
  background: rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  color: #3441b1;
}

.mobile-menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3441b1;
  width: 34px;
  height: 34px;
  background: #dde2ea;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, transform 0.2s ease-in-out;
  z-index: 1000;
}

.mobile-menu .links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
}

.nav-links {
  display: flex;
  gap: 4px;
  align-items: center;
  width: auto;
  height: 40px;
  text-decoration: none;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 7px;
  margin-right: 10px;
  border-radius: 12px;
  transition: background-color 0.2s ease, transform 0.2s ease-in-out;
  border: 1px solid transparent;
}
.nav-links:hover {
  color: rgba(0, 0, 0, 0.8);
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 1px 0px rgba(0, 0, 0, 0.07);
}
.nav-links:active {
  transform: scale(0.98);
  background: #e0e7f0;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.06), 0 0px 0px rgba(0, 0, 0, 0.08);
}
.router-link-active {
  background: #f1f3f7;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 1px 0px rgba(0, 0, 0, 0.07);
}
.router-link-active .icon {
  color: #3511ffd8;
}
@media (min-width: 959px) {
  .mobile-menu-button, .mobile-menu {
    display: none;
  }
}
</style>
