const rpcFormattedEndpoints = {
  methods: {
    collectActiveEndpoints(subscriptions) {
      const formattedEndpoints = [];

      if (!subscriptions) return formattedEndpoints;

      subscriptions.forEach((subscription) => {

        subscription.endpoints?.forEach((endpoint) => {
          if (!endpoint.is_active) return;

          // Here we filter all origins on is_active and if so, map the values back to a joined string
          const origins = endpoint.allowed_origins
            ? endpoint.allowed_origins
                .filter(allowedOrigin => allowedOrigin && allowedOrigin.is_active)
                .map(allowedOrigin => allowedOrigin.value)
                .join(', ')
            : '';

          endpoint.values?.forEach((endpointValue) => {
            formattedEndpoints.push({
              name: endpoint.name || '',
              value: endpointValue,
              origins: origins
            });
          });
        });
      });

      return formattedEndpoints;
    },
  }
};

export default rpcFormattedEndpoints;
