import ReconnectingWebSocket from 'reconnecting-websocket';

export class MattermostWebSocketClient {
  constructor(host, token) {
    this.host = host;
    this.token = token;
    this.isConnected = false;
    this.websocket = null;
    this.eventListeners = new Map();
  }

  addEventListener(eventName, callback) {
    if (!this.eventListeners.has(eventName)) {
      this.eventListeners.set(eventName, new Set());
    }
    this.eventListeners.get(eventName).add(callback);
  }

  removeEventListener(eventName, callback) {
    if (this.eventListeners.has(eventName)) {
      this.eventListeners.get(eventName).delete(callback);
    }
  }

  emit(eventName, data) {
    if (this.eventListeners.has(eventName)) {
      this.eventListeners.get(eventName).forEach(callback => callback(data));
    }
  }

  initialize() {
    this.websocket = new ReconnectingWebSocket(this.getWebSocketUrl());
    this.websocket.addEventListener('open', this.onOpen.bind(this));
    this.websocket.addEventListener('message', this.onMessage.bind(this));
  }

  onOpen() {
    const jsonPayload = JSON.stringify({
      action: 'authentication_challenge',
      seq: 1,
      data: { token: this.token }
    });

    this.websocket.send(jsonPayload);
  }

  onMessage(event) {
    if (event.type === 'message') {
      const data = JSON.parse(event.data);
      if (data.event === 'posted') {
        const post = JSON.parse(data.data.post);
        console.log(post);

        // Emit the message event with the post data and sender information
        this.emit('message', {
          post,
          senderName: data.data.sender_name
        });
      }
    }
  }

  disconnect() {
    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
    }
  }

  getWebSocketUrl() {
    if (/^localhost/.test(this.host)) {
      return 'ws://' + this.host + '/api/v4/websocket';
    } else {
      return 'wss://' + this.host + '/api/v4/websocket';
    }
  }
}

export default MattermostWebSocketClient;
