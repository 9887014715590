<template>
  <div v-if="showCoinflowInfo" class="card">
    <div class="card-header">
      <h2>Coinflow events & payments</h2>
      <div v-if="planName" class="mt-1 fs-0">
        <span class="font-weight-bold">Plan:</span> {{ planName }}
      </div>
    </div>

    <div class="card-body">
      <div class="table-responsive table-hover scrollbar">
        <table class="table table-sm">
          <thead class="border-bottom">
            <tr>
              <th>Event Type</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading" role="row">
              <td colspan="6">
                <div class="text-center my-4">
                  <b-spinner class="align-middle mr-3"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </td>
            </tr>

            <tr v-for="webhookRequest in coinflowWebhookRequests" :key="webhookRequest.uuid" class="align-middle">
              <td>
                <b-link v-b-modal="'coinflow-webhook-request-modal-' + webhookRequest.uuid">
                  {{ webhookRequest.event_type }}
                </b-link>
              </td>
              <td>{{ formatDate(webhookRequest.created_at) }}</td>
              <b-modal
                centered
                size="lg"
                :id="'coinflow-webhook-request-modal-' + webhookRequest.uuid"
                class="p-3"
                header-bg-variant="light"
                header-class="py-3"
                title="Coinflow event details"
                :ok-only="true"
                ok-title="Close"
              >
                <div v-if="webhookRequest">
                  <b-card-text>
                    <div class="mb-3"><b>Account UUID:</b> {{ webhookRequest.account_uuid }}</div>
                    <div class="mb-3"><b>Email:</b> {{ webhookRequest.email }}</div>
                    <div class="mb-3"><b>Event Type:</b> {{ webhookRequest.event_type }}</div>
                    <div class="mb-3"><b>Processed At:</b> {{ formatDate(webhookRequest.processed_at) }}</div>
                    <div class="mb-3"><b>Created At:</b> {{ formatDate(webhookRequest.created_at) }}</div>
                    <div class="mb-3"><b>Updated At:</b> {{ formatDate(webhookRequest.updated_at) }}</div>
                    <div class="mb-3"><b>Body:</b></div>
                    <pre class="json-body mb-3">{{ formatBody(webhookRequest.body) }}</pre>
                  </b-card-text>
                </div>
              </b-modal>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="fs--1">{{ coinflowWebhookRequests.length }} Event(s)</p>
    </div>
  </div>
</template>

<script>
import http from '@/services/http';

export default {
  name: 'CoinflowWebhookRequestsCard',

  data() {
    return {
      coinflowWebhookRequests: [],
      isLoading: false,
    };
  },

  created() {
    this.onCreated();
  },

  computed: {
    showCoinflowInfo() {
      return this.coinflowWebhookRequests.length > 0;
    },

    planName() {
      // Find the most recent "Subscription Created" event
      const subscriptionCreatedEvent = this.coinflowWebhookRequests.find(
        webhook => webhook.event_type === 'Subscription Created'
      );

      if (subscriptionCreatedEvent) {
        try {
          const body = JSON.parse(subscriptionCreatedEvent.body);
          return body.data?.planName || null;
        } catch (e) {
          return null;
        }
      }

      return null;
    }
  },

  methods: {
    async onCreated() {
      try {
        this.isLoading = true;
        const response = await http.get(`admin/coinflow_webhook_requests?account_uuid=${this.$route.params.uuid}`);
        this.coinflowWebhookRequests = response.data.coinflow_webhook_requests;
      } catch (error) {
        this.$bvToast.toast('Could not fetch coinflow events', {
          variant: 'danger'
        });
      } finally {
        this.isLoading = false;
      }
    },

    formatBody(body) {
      try {
        return JSON.stringify(JSON.parse(body), null, 2)
      } catch (e) {
        return body
      }
    },

    formatDate(date) {
      if (!date) return ''
      return date.replace('T', ' ')
    }
  },
};
</script>

<style scoped>
.json-body {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 1rem;
  white-space: pre-wrap;
}
</style>
