<template>
  <div class="table-responsive scrollbar">
    <div class="form-group">
      <b-form v-on:submit.prevent="searchSubscriptionTypes">
        <div class="form-group">
          <b-input v-model="searchSubscriptionTypesTerm" placeholder="Search by name" type="search" />
        </div>
      </b-form>
    </div>

    <table class="table table-sm table-hover overflow-hidden mb-3">
      <thead>
        <tr class="btn-reveal-trigger">
          <th scope="col">Internal Name</th>
          <th>Display Name</th>
          <th>Pool Name</th>
          <th class="text-right"># Subs</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading" role="row">
          <td colspan="6">
            <div class="text-center my-4">
              <b-spinner class="align-middle mr-3"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </td>
        </tr>

        <tr v-for="st in displayedItems" :key="st.uuid" class="btn-reveal-trigger">
          <td>
            <b-link @click="showModal(st.uuid)">
              {{ st.name }}
            </b-link>
          </td>
          <td>{{ st.display_name }}</td>
          <td>{{ st.pool_name }}</td>
          <td class="text-right">
            {{ st.subscriptions_count }}
          </td>
        </tr>
      </tbody>
    </table>

    <subscription-type-modal
      v-if="rateTiers.length && selectedStUuid"
      :uuid="selectedStUuid"
      :rate-tiers="rateTiers"
    />

    <b-pagination-nav
      :link-gen="linkGen"
      v-model="currentPage"
      :number-of-pages="totalPages"
      use-router
    />

    <p class="fs--1">{{ totalCount }} Subscription Types</p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import subscriptionTypeModal from './subscription-type-modal.vue';

export default {
  components: { subscriptionTypeModal },

  data() {
    return {
      newSubscriptionTypeUuid: '',
      searchSubscriptionTypesTerm: '',
      filteredSubscriptionTypes: [],
      rateTiers: [],
      selectedStUuid: null,
      isLoading: false,
      currentPage: 1,
      perPage: 25
    }
  },

  watch: {
    searchSubscriptionTypesTerm: 'searchSubscriptionTypes',
  },

  computed: {
    ...mapState('subscriptionTypes', ['subscriptionTypes']),

    totalCount() {
      return this.filteredSubscriptionTypes.length;
    },

    totalPages() {
      return Math.ceil(Math.max(this.totalCount, 1) / this.perPage);
    },

    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.filteredSubscriptionTypes.slice(startIndex, endIndex);
    }
  },

  created() {
    this.onCreated();
  },

  methods: {
    ...mapActions('rateTiers', ['fetchRateTiers']),
    ...mapActions('subscriptionTypes', ['fetchSubscriptionTypes']),

    async onCreated() {
      try {
        this.isLoading = true;
        await this.fetchSubscriptionTypes();
        this.rateTiers = await this.fetchRateTiers();
        this.calculateFilteredSubscriptionTypes();
        this.loadCurrentPage();
      }

      finally {
        this.isLoading = false;
      }
    },

    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?subscription_types_page=${pageNum}`;
    },

    searchSubscriptionTypes() {
      if (this.searchSubscriptionTypesTerm !== null) {
        this.calculateFilteredSubscriptionTypes();
        this.setCurrentPageTo(1);
      }
    },

    calculateFilteredSubscriptionTypes() {
      if (this.searchSubscriptionTypesTerm) {
        this.filteredSubscriptionTypes = this.subscriptionTypes.filter(subscriptionType =>
          subscriptionType.name.toLowerCase().includes(this.searchSubscriptionTypesTerm.toLowerCase()) ||
              subscriptionType.display_name?.toLowerCase().includes(this.searchSubscriptionTypesTerm.toLowerCase()) ||
              subscriptionType.pool_name?.toLowerCase().includes(this.searchSubscriptionTypesTerm.toLowerCase())
        );
      } else {
        this.filteredSubscriptionTypes = this.subscriptionTypes;
      }
    },

    loadCurrentPage() {
      if (this.$route.query.subscription_types_page) {
        this.currentPage = parseInt(this.$route.query.subscription_types_page);
      } else {
        this.currentPage = 1;
      }
    },

    setCurrentPageTo(pageNum) {
      if (this.currentPage !== pageNum) {
        this.currentPage = pageNum;
        this.$router.push({ path: 'admins', query: { subscription_types_page: pageNum }})
      }
    },

    showModal(uuid) {
      this.selectedStUuid = uuid;
      this.$nextTick(() => {
        this.$bvModal.show('modal-' + uuid);
      });
    },
  }
}
</script>
