<template>
  <div class="container-xxl py-2">
    <nav aria-label="breadcrumb" class="my-3">
      <ol class="breadcrumb m-0 p-0 font-weight-bold" style="background: none;">
        <li class="breadcrumb-item">
          <router-link
            class="text-primary"
            :to="{ name: 'editAccount', params: { uuid: accountUuid } }"
          >
            Account: {{
              subscription ? subscription.account.name : subscriptions[0]?.account?.name
            }}
          </router-link>
        </li>
        <template v-if="subscription">
          <li class="breadcrumb-item">
            <router-link
              class="text-primary"
              :to="{ name: 'editSubscription', params: { uuid: subscription.uuid } }"
            >
              Subscription: {{ subscription.name }}
            </router-link>
          </li>
        </template>
        <li class="breadcrumb-item active text-muted" aria-current="page">
          RPC Metrics
        </li>
      </ol>
    </nav>

    <div class="card">
      <div class="card-header text-center sticky-top">
        <div class="row">
          <div class="col">
            <div class="float-left">
              <div class="mb-1">
                {{ selectedRange }}
              </div>
              <div>
                <date-range-picker
                  time-picker
                  time-picker24-hour
                  auto-apply
                  opens="right"
                  :locale-data="{ format: 'yyyy/mm/dd HH:MM' }"
                  v-model="dateRange"
                  :ranges="dates"
                  append-to-body
                  @select="handleSelect"
                />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mb-1">
              Auto Refresh
              <b-button
                v-if="selectedAutoRefresh === 'none'"
                class="ml-1"
                variant="outline-secondary"
                size="sm"
                @click="fetchMetrics"
              >
                <b-icon
                  icon="arrow-clockwise"
                  aria-hidden="true"
                  v-b-popover.hover.top="'Refresh now'"
                />
              </b-button>
            </div>
            <div>
              <b-form-radio-group
                v-model="selectedAutoRefresh"
                :options="autoRefreshOptions"
                buttons
                button-variant="outline-secondary"
                size="sm"
              />
            </div>
          </div>
          <div class="col float-right">
            <div>
              <div class="mb-1">Selected resource</div>
              <div>
                <b-form-group>
                  <b-dropdown
                    ref="dropdown"
                    id="resource-dropdown-cleaned"
                    :html="selectedResourceText"
                    right
                    variant="outline-secondary"
                    @show="onDropdownShow"
                    menu-class="resource-dropdown-menu"
                  >
                    <!-- Search Box with Search and Clear Icons -->
                    <b-dropdown-item class="p-2 search-box-item">
                      <div class="d-flex align-items-center">
                        <b-icon icon="search" aria-hidden="true" class="me-2"></b-icon>
                        <input
                          type="text"
                          class="form-control"
                          v-model="searchQuery"
                          placeholder="Search..."
                          @input="filterDropdownOptions"
                          @click="preventDropdownClose"
                        />
                        <b-icon
                          v-if="searchQuery"
                          icon="x"
                          class="ms-2 cursor-pointer"
                          @click="clearSearch"
                        ></b-icon>
                      </div>
                    </b-dropdown-item>

                    <!-- Filtered dropdown options -->
                    <div v-for="(group, groupIndex) in filteredDropdownOptions"
                         :key="'group-' + groupIndex">

                      <template v-if="group.label">
                        <b-dropdown-item
                          v-if="group.label"
                          @click="selectResource({ text: group.label, value: { uuid: group.uuid, name: 'subscription' } })"
                          class="dropdown-header-item"
                        >
                            <span class="dropdown-option">
                              {{ group.label }}
                              <!-- Checkmark only if the subscription group is selected -->
                              <span
                                v-if="selectedResource?.value?.uuid === group.uuid && selectedResource?.value?.name === 'subscription'"
                                class="checkmark">✓</span>
                            </span>
                        </b-dropdown-item>
                      </template>

                      <b-dropdown-item
                        v-for="(option, optionIndex) in group.options"
                        :key="'option-' + groupIndex + '-' + optionIndex"
                        @click="selectResource(option)"
                        :disabled="option.disabled"
                      >
                          <span class="dropdown-option">
                            <b-icon
                              :icon="option.value.name === 'endpoint' ? 'diagram-3' : 'shield-lock'"
                              v-b-popover.hover.top="option.value.name === 'endpoint' ? 'Endpoint' : 'Token'"
                              aria-hidden="true"
                              class="me-2"
                            />
                            {{ option.text }}
                          <span
                            v-if="selectedResource?.value?.uuid === option.value.uuid && selectedResource?.value?.name === option.value.name"
                            class="checkmark">✓</span>
                          </span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <b-alert
          dismissible
          fade
          :show="showAlert"
          @dismissed="showAlert=false"
          variant="danger"
          class="my-3"
        >
          There was an error fetching metrics
        </b-alert>
      </div>

      <div v-if="isLoading" class="card-body text-center py-5">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading...</strong>
      </div>

      <div class="card-body">
        <!-- Show a message if no charts have data -->
        <div v-if="!isLoading && !hasAnyChartData" class="text-center py-5">
          <h4>No available chart data</h4>
        </div>

        <!-- All charts are always rendered -->
        <div>
          <echart
            chart-name="rps"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-name="rps-by-origin"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-name="traffic-in-mbits"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-name="das-by-method"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-name="rps-by-method"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-name="rps-by-status"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-name="traffic-by-method"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-id="response-time-by-method-50"
            chart-name="response-time-by-method"
            :metric-params="{ quantile: '0.5' }"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-id="response-time-by-host-50"
            chart-name="response-time-by-host"
            :metric-params="{ quantile: '0.5' }"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-id="response-time-by-method-90"
            chart-name="response-time-by-method"
            :metric-params="{ quantile: '0.9' }"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-id="response-time-by-method-95"
            chart-name="response-time-by-method"
            :metric-params="{ quantile: '0.95' }"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />

          <echart
            chart-name="egress-bytes"
            :options="chartOptions"
            @data-status="handleChartDataStatus"
            @fetch-error="handleFetchError"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import http from '@/services/http';
import {mapGetters} from 'vuex';
import echart from '@/components/globals/echart';
import metricsMixin from '@/mixins/metrics';

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    echart,
    DateRangePicker
  },

  name: 'AccountMetrics',

  mixins: [metricsMixin],

  data() {
    return {
      isLoading: true,
      dateRange: {
        startDate: null,
        endDate: null
      },
      showAlert: false,
      selectedRange: 'Last 3 Hours',
      selectedResource: null,
      selectedAutoRefresh: 'none',
      subscriptions: [],
      subscription: null,
      endpoints: [],
      tokens: [],
      dropdownOptions: [],
      filteredDropdownOptions: [],
      searchQuery: '',
      isClosable: false,
      chartsWithData: {},
      chartIds: [
        'rps',
        'rps-by-origin',
        'traffic-in-mbits',
        'das-by-method',
        'rps-by-method',
        'rps-by-status',
        'traffic-by-method',
        'response-time-by-method-50',
        'response-time-by-host-50',
        'response-time-by-method-90',
        'response-time-by-method-95',
        'egress-bytes'
      ]
    }
  },

  created() {
    this.onCreated();
  },

  beforeDestroy() {
    this.clearInterval();
  },

  watch: {
    selectedAutoRefresh: function (newVal) {
      this.clearInterval(this.autoRefreshInterval);

      if (newVal !== 'none') {
        this.autoRefreshInterval = setInterval(this.fetchMetrics, parseInt(newVal) * 60 * 1000);
      }
    },

    selectedRange: function () {
      this.showAlert = false;
      this.fetchMetrics(); // Refresh metrics when range changes
    },

    selectedResource: function () {
      this.showAlert = false;
      this.fetchMetrics(); // Refresh metrics when resource changes
    }
  },

  computed: {
    ...mapGetters('sessions', [
      'currentUser'
    ]),

    chartOptions() {
      const options = {
        accountUuid: this.accountUuid,
        subscriptionUuid: this.subscriptionUuid,
        endpointUuid: this.endpointUuid,
        tokenUuid: this.tokenUuid,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
      };
      return options;
    },

    hasAnyChartData() {
      // If we have any chart with data, return true
      return Object.values(this.chartsWithData).some(hasData => hasData);
    },

    canDisplayComponents() {
      // If we're not loading and we have any chart with data or any error, return true
      return !this.isLoading && (this.hasAnyChartData || this.showAlert);
    },

    subscriptionUuid() {
      if (this.selectedSubscriptionUuid) {
        return this.selectedSubscriptionUuid;
      } else if (this.selectedResource?.value?.name === 'subscription') {
        return this.selectedResource.value.uuid;
      }
    },

    endpointUuid() {
      if (this.selectedResource?.value?.name === 'endpoint') {
        return this.selectedResource.value.uuid;
      }
    },

    tokenUuid() {
      if (this.selectedResource?.value?.name === 'token') {
        return this.selectedResource.value.uuid;
      }
    },

    accountUuid() {
      return this.$route.params.uuid;
    },

    selectedSubscriptionUuid() {
      return this.$route.query.selected_subscription_uuid;
    },

    selectedEndpointUuid() {
      return this.$route.query.selected_endpoint_uuid;
    },

    selectedTokenUuid() {
      return this.$route.query.selected_token_uuid;
    },

    isCurrentUserAdmin() {
      return this.currentUser?.is_admin;
    },

    selectedResourceText() {
      if (!this.selectedResource) {
        return 'All'; // Default to "All" if no resource is selected
      }

      const icon = this.selectedResource.value.name === 'endpoint' ? 'diagram-3' : 'shield-lock';

      return `<b-icon icon="${icon}" aria-hidden="true" class="me-2"></b-icon> ${this.selectedResource.text}`;
    }
  },

  methods: {
    async onCreated() {
      try {
        this.isLoading = true;
        this.dateRange.startDate = this.threeHoursAgo();
        this.dateRange.endDate = this.now();
        this.setCalendarDates();

        if (this.selectedSubscriptionUuid) {
          await this.fetchSubscription();
        } else {
          await this.fetchSubscriptions();
        }

        this.setCalendarDates();

        // Reset charts with data
        this.chartsWithData = {};
      } catch (error) {
        console.error('Error initializing metrics:', error);
        this.showAlert = true;
      }
    },

    handleChartDataStatus({chartId, hasData, error}) {
      this.$set(this.chartsWithData, chartId, hasData);

      // Check if all charts have reported back
      const reportedCharts = Object.keys(this.chartsWithData).length;

      // Log which charts haven't reported back yet
      if (reportedCharts < this.chartIds.length) {
        const missingCharts = this.chartIds.filter(id => !this.chartsWithData.hasOwnProperty(id));
      }

      // Clear loading state if at least one chart has data or if all charts have reported back
      if ((hasData && this.isLoading) || reportedCharts >= this.chartIds.length) {
        this.isLoading = false;
      }

      if (error && !this.showAlert) {
        console.error('Handle chart data status error:', error);
        this.showAlert = true;
      }
    },

    async fetchSubscription() {
      try {
        const subscriptionResponse = await http.get(`subscriptions/${this.selectedSubscriptionUuid}`);
        this.subscription = subscriptionResponse.data.subscription;
        this.endpoints = this.subscription.endpoints;
        this.tokens = this.subscription.tokens;

        if (this.subscription.account?.uuid !== this.accountUuid) {
          this.showNotFoundPage('Account with given UUID cannot be found.');
        }

        // Generate dropdown options after fetching the subscription
        this.generateDropdownOptions();
      } catch (error) {
        // console.log(error);

        if (error.status === 404) {
          this.showNotFoundPage('Subscription with given UUID cannot be found.');
        }
      }
    },

    async fetchSubscriptions() {
      try {
        const response = await http.get(`subscriptions?account_uuid=${this.accountUuid}`);
        this.subscriptions = response.data.subscriptions;
        // Populate both dropdowns on component mount
        this.generateDropdownOptions();
      } catch (error) {
        // console.log(error);

        if (error.status === 404) {
          this.showNotFoundPage('Subscriptions with given account UUID cannot be found.');
        }
      }
    },

    showNotFoundPage(message) {
      this.$router.push({
        name: 'notFound',
        params: {message: message}
      });
    },

    preselectResource() {
        // If we have a selected endpoint or token, preselect it
        if (this.selectedEndpointUuid || this.selectedTokenUuid) {
          // Flatten all options from all groups to make search easier
          const allOptions = this.dropdownOptions
            .filter(group => group.options)
            .flatMap(group => group.options);

          const matchingOption = allOptions.find(option => {
            if (this.selectedEndpointUuid && option.value.name === 'endpoint') {
              return option.value.uuid === this.selectedEndpointUuid;
            } else if (this.selectedTokenUuid && option.value.name === 'token') {
              return option.value.uuid === this.selectedTokenUuid;
            }
            return false;
          });

          if (matchingOption) {
            this.selectedResource = matchingOption;
          }
        }
    },

    endpointsDropdownOptions(endpoints, subscriptionName = null) {
      const options = endpoints.flatMap(e => {
        // Create separate options for each value in 'values' array, but exclude 'devnet'
        return e.values
          .filter(value => !value.includes('devnet') && !value.includes('dev'))
          .map(value => {
            let text = `${value}`;
            if (e.name) {
              text = `${text} (${e.name})`;
            }
            return {
              text: `  ${text}`,
              value: {uuid: e.uuid, name: 'endpoint'},
              disabled: this.isDisabled(e)
            };
          });
      });

      return {
        label: subscriptionName ? `  Subscription: ${subscriptionName} endpoints` : '  endpoints',
        options: options
      };
    },

    tokensDropdownOptions(tokens, subscriptionName = null) {
      const options = tokens
        .filter(t => !(t.auth_username && (t.auth_username.includes('dev') || t.auth_username.includes('developer'))) &&
          !(t.name && (t.name.includes('dev') || t.name.includes('developer')))) // Filter out 'dev' or 'developer'
        .map(t => {
          let text = t.auth_username;
          if (t.name) {
            text = `${text} (${t.name})`;
          }
          return {
            text: `  ${text}`,
            value: {uuid: t.uuid, name: 'token'},
            disabled: this.isDisabled(t)
          };
        });

      return {
        label: subscriptionName ? `  Subscription: ${subscriptionName} tokens` : '  tokens', // Indented label
        options: options
      };
    },

    isDisabled(object) {
      return !this.isCurrentUserAdmin && !object.is_active;
    },

    handleSelect(json) {
      const delta = json.endDate - json.startDate;
      for (const range in this.dates) {
        const [start, end] = this.dates[range];
        if (end - start === delta) {
          this.selectedRange = range;
          break;
        }

        this.selectedRange = 'Custom';
      }

      this.setCalendarDates();
    },

    // Refresh all charts
    async fetchMetrics() {
      this.showAlert = false;
      this.isLoading = true;
      this.setCalendarDates();

      // Create a new object for dateRange to ensure Vue detects the change
      this.dateRange = {
        startDate: this.dates[this.selectedRange][0],
        endDate: this.dates[this.selectedRange][1]
      };

      // Reset charts with data
      this.chartsWithData = {};

      // The echart components will automatically refetch when their props change
    },

    handleFetchError(error) {
      if (!this.showAlert) {
        this.showAlert = true;

        // Stop loading when an error occurs
        this.isLoading = false;
      }
    },

    selectResource(option) {
      this.isClosable = true;

      if (option.value === null) {
        // If "All" is selected, reset to the initial state
        this.selectedResource = null;
      } else {
        // Otherwise, set the selected option
        this.selectedResource = option;
      }

      this.$refs.dropdown.hide(); // Close the dropdown after selection
      this.$emit('hide');
    },

    generateDropdownOptions() {
      const allOption = {label: 'All', value: null};
      let subscriptionOptions = [];

      // If we already have the subscription, use it for the options
      if (this.subscription) {
        const subscriptionName = this.subscription.name;
        const endpoints = this.endpointsDropdownOptions(this.subscription.endpoints, subscriptionName);
        const tokens = this.tokensDropdownOptions(this.subscription.tokens, subscriptionName);

        if (endpoints.options.length > 0 || tokens.options.length > 0) {
          subscriptionOptions.push({
            label: `Subscription: ${subscriptionName}`,
            uuid: this.subscription.uuid,
            options: [
              ...endpoints.options,
              ...tokens.options
            ]
          });
        }
      } else {
        // If we have multiple subscriptions (from fetchSubscriptions)
        subscriptionOptions = this.subscriptions.map(subscription => {
          const subscriptionName = subscription.name;
          const endpoints = this.endpointsDropdownOptions(subscription.endpoints, subscriptionName);
          const tokens = this.tokensDropdownOptions(subscription.tokens, subscriptionName);

          return {
            label: `Subscription: ${subscriptionName}`,
            uuid: subscription.uuid, // Ensure the group (subscription) header has a uuid
            options: [
              ...endpoints.options,
              ...tokens.options
            ]
          };
        }).filter(group => group.options.length > 0);
      }

      // Insert 'All' at the start of the dropdown options
      this.dropdownOptions = [allOption, ...subscriptionOptions];
      this.filteredDropdownOptions = this.dropdownOptions;

      // Call preselectResource after dropdown options are generated
      this.preselectResource();
    },

    filterDropdownOptions() {
      const query = this.searchQuery.toLowerCase();

      // If search query is empty, reset the filtered options to original
      if (!query) {
        this.filteredDropdownOptions = this.dropdownOptions;
        this.isClosable = true;
        return;
      }

      this.isClosable = false;

      // filter the dropdownOptions based on searchQuery
      this.filteredDropdownOptions = this.dropdownOptions.map(group => {
        if (!group || !group.options) {
          return {...group, options: []};
        }

        // Filter endpoints and tokens separately
        const filteredEndpoints = group.options.filter(option =>
          option.value.name === 'endpoint' && option.text && option.text.toLowerCase().includes(query)
        );

        const filteredTokens = group.options.filter(option =>
          option.value.name === 'token' && option.text && option.text.toLowerCase().includes(query)
        );

        // only show the group if there are matches in either endpoints or tokens
        const showGroup = filteredEndpoints.length > 0 || filteredTokens.length > 0;

        return {
          label: group.label,
          options: [...filteredEndpoints, ...filteredTokens],
          show: showGroup
        };
      })
        .filter(group => group.options && group.options.length > 0);
    },

    preventDropdownClose(event) {
      event.stopPropagation();
    },

    clearSearch() {
      this.preventDropdownClose(event);
      this.searchQuery = '';
      this.filteredDropdownOptions = this.dropdownOptions;
    },

    onDropdownHide(bvEvent) {
      if (!this.isClosable) {
        bvEvent.preventDefault(); // Prevent dropdown from closing if not closable
      }
    },

    keepDropdownOpen() {
      this.isClosable = false; // prevent closing while typing
      this.$refs.dropdown.show(); // force dropdown stays open while searching
    },

    resetClosable() {
      this.isClosable = true; // clear closable state when dropdown is reopened
    },

    onDropdownShow() {
      this.resetClosable();
      this.focusSearchInput();
      this.setDropdownWidth();
    },

    focusSearchInput() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
          }
        });
      });
    },

    setDropdownWidth() {
      this.$nextTick(() => {
        setTimeout(() => {
          const dropdownMenu = this.$refs.dropdown.$el.querySelector('.dropdown-menu');
          if (dropdownMenu) {
            const initialWidth = dropdownMenu.offsetWidth;
            dropdownMenu.style.minWidth = `${initialWidth}px`;
          }
        });
      });
    },
  }
}
</script>

<style scoped>
.metrics {
  margin-bottom: 50px;
  width: 100%;
  height: 400px;
}

.checkmark {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}

/* Global styles for all dropdowns */
::v-deep .dropdown-menu {
  max-height: 80vh !important; /* Limit to 80% of viewport height */
  overflow-y: auto !important; /* Enable vertical scrolling */
  z-index: 9999 !important; /* Ensure dropdown appears above top navigation */
}

/* Ensure the dropdown container has proper positioning */
.dropdown.b-dropdown {
  width: 100%;
  text-align: left;
  position: relative;
}

/* Make the search box sticky at the top of the dropdown */
::v-deep .dropdown-menu.resource-dropdown-menu {
  padding-top: 0 !important;
}

.search-box-item {
  position: sticky !important;
  top: 0 !important;
  z-index: 1000 !important;
  background-color: white !important;
  margin-bottom: 0 !important;
  padding: 8px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
  border-bottom: 1px solid #dee2e6 !important;
  width: 100% !important;
  display: block !important;
}

/* Add padding to the top of the first non-search item */
::v-deep .dropdown-menu.resource-dropdown-menu > div:first-of-type {
  padding-top: 8px !important;
}

/* Ensure the dropdown items don't overlap with the search box */
.dropdown-menu.resource-dropdown-menu .dropdown-item:not(.search-box-item) {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/* Fix for dropdown positioning */
::v-deep .dropdown-menu.resource-dropdown-menu {
  position: absolute !important;
  z-index: 9999 !important;
  max-height: 80vh !important;
  overflow-y: auto !important;
  transform: translate3d(-319px, 35px, 0px) !important;
}

.dropdown-option {
  font-size: 1rem;
}

.dropdown-option.indented {
  margin-left: 8px;
}

/** we need v-deep here to force the css compile for class rendered in component */
.dropdown-header-item::v-deep .dropdown-item {
  font-size: 1rem !important;
  padding: 0.25rem 1rem !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  letter-spacing: 0.04em !important;
}

/* ::v-deep to target the button within the dropdown */
::v-deep #resource-dropdown-cleaned .dropdown-toggle {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

/* Add margin between the icon and text */
.me-2 {
  margin-right: 8px;
}

.ms-2 {
  margin-left: 8px;
}

.form-control {
  width: 100%;
  padding: 4px 8px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
