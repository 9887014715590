<template>
  <div class="rpc-card card mb-3">
    <div
      class="bg-holder d-none d-lg-block bg-card"
      :style="{ backgroundImage: 'url(' + require('@/assets/corner-4.png') + ')' }"
    />

    <div class="card-header">
      <span class="h2">Your RPC Summary</span>

      <b-button
        @click="rpcExpanded = !rpcExpanded"
        class="ml-3 collapse-button primary"
        variant="outline-secondary"
        size="sm"
      >
        <b-icon icon="chevron-expand"></b-icon>
      </b-button>
    </div>
    <b-collapse v-model="rpcExpanded" id="rpc-summary-collapse">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <p>
              Subscriptions are how RPC access is managed, and isolates access across blockchains
              and their environments.
            </p>
            <p>
              To use your RPC with frontend applications, you can call one of your configured
              endpoints from an allowed origin of your choosing. You must provide an allowed origin
              for public-facing apps.
            </p>
            <p>
              For developers or backend servers, you must append an access token to your endpoint
              for higher rate limits. Please be careful to not leak your tokens through your website
              or public code repositories.
            </p>
            <p>
              For support, you can reach us through the live chat in the bottom right corner of the screen, email us at
              <span class="font-weight-bold">help@triton.one</span>, or join our
              <a href="https://t.me/joinchat/K0ONdq7fE4s0Mjdl" target="_blank" class="font-weight-bold">
                Telegram community
              </a>.
            </p>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12">
            <div v-if="isLoading">
              <div class="text-center my-4">
                <b-spinner class="align-middle mr-3"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </div>
            <div v-else>
              <b-tabs
                pills
                card
                vertical
                class="rounded"
                v-if="anyResourcesToShow"
                :nav-class="filteredSubscriptionTypes.length < 2 ? 'd-none' : ''"
              >
                <b-tab
                  v-for="name in filteredSubscriptionTypes"
                  :key="name"
                  :title="displayName(name)"
                >
                  <template v-if="hasActiveEndpoints(name)">
                    <h4 class="mb-3">{{ displayName(name) }} subscription(s)</h4>
                    <div class="mb-4">
                      <rpc-endpoints :subscriptions="findSubscriptionsBySubscriptionType(name)" />
                      <rpc-tokens :subscriptions="findSubscriptionsBySubscriptionType(name)" />
                    </div>
                  </template>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<style lang="scss" scoped>
.rpc-card.card {
  .btn.clipboard-btn {
    padding: 5px;
    color: #5e6e82;
    border: none;
    background-color: inherit;
  }

  .bg-holder {
    background-position: right 0 top 0;
    border-radius: 20px;
  }

  .tabs {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }

  .nav-pills .nav-link.active {
    background-color: #007bff;
  }

  .collapse-button {
    position: relative;
    top: -4px;
  }

  .card-header {
    background: transparent;
    z-index: 1;
  }
}
</style>

<style lang="scss">
.rpc-card.card .nav.card-header {
  background-color: rgba(0, 0, 0, 0.03);
}
</style>

<script>
import rpcFormattedEndpoints from '@/mixins/rpc-formatted-endpoints';
import rpcEndpoints from './rpc-endpoints';
import rpcTokens from './rpc-tokens';
import _ from 'lodash';

export default {
  name: 'UsersRpcSummary',

  components: {
    rpcEndpoints,
    rpcTokens,
  },

  mixins: [rpcFormattedEndpoints],

  props: {
    isLoading: { type: Boolean, default: () => true },
    subscriptions: { type: Array, default: () => [] },
  },

  data() {
    return {
      endpointsPaginationData: [],
      tokensPaginationData: [],
      rpcExpanded: true,
      perPageDefault: 8,
    };
  },

  watch: {
    rpcExpanded: 'setRpcExpandStatus',
  },

  computed: {
    foundSubscriptionTypes() {
      return _.uniqBy(
        this.subscriptions.map((subscription) => subscription.subscription_type),
        'name'
      );
    },

    subscriptionTypeNames() {
      return _.sortBy(this.foundSubscriptionTypes.map((subscriptionType) => subscriptionType.name));
    },

    anyResourcesToShow() {
      return this.filteredSubscriptionTypes.length > 0;
    },

    filteredSubscriptionTypes() {
      return this.subscriptionTypeNames.filter((STName) => {
        return this.hasActiveEndpoints(STName);
      });
    },
  },

  created() {
    this.onCreated();
  },

  methods: {
    onCreated() {
      this.getRpcExpandStatus();
    },

    findSubscriptionsBySubscriptionType(STName) {
      return this.subscriptions.filter(
        (subscription) => subscription.subscription_type.name === STName
      );
    },

    hasActiveEndpoints(STName) {
      return this.activeEndpoints(STName).length > 0;
    },

    activeEndpoints(STName) {
      return this.collectActiveEndpoints(this.findSubscriptionsBySubscriptionType(STName));
    },

    displayName(STName) {
      const subscriptionType = this.foundSubscriptionTypes.find((st) => st.name === STName);
      return subscriptionType.display_name || subscriptionType.name;
    },

    getRpcExpandStatus() {
      const savedStatus = localStorage.getItem('rpcExpanded');

      if (savedStatus) {
        this.rpcExpanded = savedStatus === 'true';
      }
    },

    setRpcExpandStatus() {
      localStorage.setItem('rpcExpanded', this.rpcExpanded);
    },
  },
};
</script>
