<template>
  <div>
    <b-form v-on:submit.prevent="signIn">
      <div class="mb-3">
        <label for="username">Username</label>
        <b-input
          v-model="username"
          placeholder="Username"
          autofocus
          data-cy="username-input"
        />
      </div>
      <div class="mb-3">
        <label for="password">Password</label>
        <b-input
          v-model="password"
          placeholder="Password"
          type="password"
          autocomplete="off"
          data-cy="password-input"
        />
      </div>

      <div class="my-3">
        <b-button
          :disabled="isLoading"
          type="submit"
          class="primary-button d-block w-100 mt-3"
          data-cy="sign-in-button"
        >
          <span v-if="isLoading">
            <b-spinner small></b-spinner>
            <span class="sr-only">Loading...</span>
          </span>
          <span class="emboss" v-else>Log in</span>
        </b-button>
      </div>
    </b-form>
    <div>
      <p class="policy-text">
        Did you
        <router-link to="/users/password/new" @click.native="closeModal">
          forget your password
        </router-link>
        ,
        <router-link to="/users/username/retrieve" @click.native="closeModal">
          forget your username
        </router-link>
        or
        <router-link to="/users/confirmation/new" @click.native="closeModal">
          didn't receive confirmation instructions?
        </router-link>
        Let's get you sorted.
      </p>
    </div>

    <transition name="shake" mode="in-out">
      <div v-if="resetPasswordMsg || recoveryMsg" class="alert alert-danger" role="alert">
        <!-- <b-icon icon="exclamation-triangle-fill"></b-icon> -->
        ⚠️
        {{ resetPasswordMsg || recoveryMsg }}
      </div>
    </transition>
    <transition name="shake" mode="in-out">
      <div
        v-if="signInStatus === 'error' || signInStatus === 'warning'"
        class="alert alert-danger"
        role="alert"
      >
        <!-- <b-icon icon="exclamation-triangle-fill"></b-icon> -->
        ⚠️
        {{ signInError }}
      </div>
    </transition>
    <transition name="shake" mode="in-out">
      <!-- TODO -->
      <!-- There's already signInStatus and signInError. Refactor this by combining them -->
      <div v-if="signInErrorMessage" class="alert alert-danger" role="alert">
        <!-- <b-icon icon="exclamation-triangle-fill"></b-icon> -->
        ⚠️
        {{ signInErrorMessage }}
      </div>
    </transition>

    <div v-if="!isModal && selfSignupsEnabled">
      <div class="d-flex justify-content-center">
        <div class="w-100 d-flex justify-content-between">
          <hr class="divider-right align-self-center" />
          <span class="section-text">Don&apos;t have an account?</span>
          <hr class="divider-left align-self-center" />
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <router-link to="/users/new" class="secondary-btn">Sign Up</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  name: 'SignIn',

  data() {
    return {
      username: null,
      password: null,
      isLoading: false,
      signInErrorMessage: null,
    };
  },

  destroyed() {
    this.$store.commit('sessions/CLEAR_SIGN_IN_ERROR');
  },

  props: {
    isModal: { type: Boolean, default: () => false },
    confirmUser: { type: Boolean },
    recoveryMsg: { type: String },
    resetPasswordMsg: { type: String },
  },

  watch: {
    isExpired: {
      immediate: true,
      handler: 'handleExpiredSession',
    },
  },

  computed: {
    ...mapGetters('sessions', ['currentUser', 'isOnboardingUnfinished']),
    ...mapState('sessions', ['signInStatus', 'signInError', 'isExpired']),
    ...mapState(['systemFeatures']),

    selfSignupsEnabled() {
      return !!this.systemFeatures?.self_signups_enabled;
    },

    areAllAccountsInactiveWithHelio() {
      if (this.currentUser) {
        return this.currentUser.accounts.every((a) => {
          !a.is_helio_pay_stream_active;
        });
      }
    },
  },

  methods: {
    ...mapActions('sessions', ['togglePaymentModal']),

    handleExpiredSession(expired) {
      if (expired) {
        this.signInErrorMessage = 'Your session has expired. Please re-enter your credentials to continue.';
      }
    },

    async signIn() {
      try {
        this.isLoading = true;

        const params = {
          username: this.username,
          password: this.password,
          loading: !this.isModal,
        };

        await this.$store.dispatch('sessions/signIn', params);

        if (this.isModal) {
          this.closeModal();
        } else if (this.isOnboardingUnfinished) {
          this.$router.push('/users/new');
        } else if (this.currentUser.is_admin) {
          this.$router.push('/admins/accounts');
        } else {
          this.$router.push('/users/me');
          this.togglePaymentModal();
        }

        this.password = null;
      } catch (error) {
        console.log(error);

        if (error?.status === 422) {
          this.signInErrorMessage = error.data.message;
        } else if (error?.status === 401) {
          this.signInErrorMessage = 'The email or password you entered did not match your account';
        } else {
          this.signInErrorMessage =
            "Our service doesn't seem to be responding right now. Please try again later";
        }
      } finally {
        this.isLoading = false;
      }
    },

    closeModal() {
      if (this.isModal) {
        this.$bvModal.hide('sign-in-modal');
      }
    },
  },
};
</script>

<style scoped>
label[for='username'],
label[for='password'] {
  opacity: 0.5;
}

.alert {
  border-radius: 18px;
  background: rgba(255, 6, 155, 0.1);
  color: rgba(255, 6, 155, 1);
  border: 2px dashed rgba(255, 6, 155, 0.4);
}

::v-deep .form-control {
  background-color: #f6f7ff;
  border: 1px solid #dddee5;
  border-radius: 10px;
  height: 45px;
}
</style>