import http from '@/services/http';

export default {
  namespaced: true,

  state: {
    subscriptionTypes: [],
    isLoading: false,
  },

  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },

    SET_SUBSCRIPTION_TYPES(state, subscriptionTypes) {
      state.subscriptionTypes = subscriptionTypes;
    },

    ADD_SUBSCRIPTION_TYPE(state, subscriptionType) {
      state.subscriptionTypes.unshift(subscriptionType);
    },

    UPDATE_SUBSCRIPTION_TYPE(state, subscriptionType) {
      const index = state.subscriptionTypes.findIndex(st => st.uuid === subscriptionType.uuid);
      state.subscriptionTypes.splice(index, 1, subscriptionType);
    },

    RESET_STATE(state) {
      state.isFetched = false;
      state.subscriptionTypes = [];
    }
  },

  actions: {
    async createSubscriptionType({ commit }, subscriptionTypeParams) {
      const response = await http.post('subscription_types', {
        subscription_type: subscriptionTypeParams
      });

      const createdSubscriptionType = response.data.subscription_type;
      commit('ADD_SUBSCRIPTION_TYPE', createdSubscriptionType);
      return response;
    },

    async fetchSubscriptionTypes({ commit, getters, state }) {
      try {
        commit('SET_IS_LOADING', true);
        const subscriptionTypes = await http.get('subscription_types');
        commit('SET_SUBSCRIPTION_TYPES', subscriptionTypes.data.subscription_types);
        return subscriptionTypes.data.subscription_types;
      }

      finally {
        commit('SET_IS_LOADING', false);
      }
    },

    async fetchSubscriptionType({}, uuid) {
      return (await http.get(`subscription_types/${uuid}`)).data.subscription_type;
    }
  }
}
