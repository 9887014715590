<template>
  <div v-if="hasActiveEndpoints">
    <div class="mt-4 mb-3">
      <h5 class="d-inline">Endpoints</h5>
      <span class="fs--1 ml-3">(For public-facing apps from allowed origins)</span>
    </div>

    <div v-for="endpoint in activeEndpoints" :key="endpoint.value" class="text-monospace mb-3">
      <div>
        <span v-if="endpoint.name" class="font-weight-bold">{{ endpoint.name }}</span>
      </div>
      <div class="d-flex align-items-center">
        <span>https://{{ endpoint.value }}</span>
        <b-button class="btn clipboard-btn ml-2" v-b-tooltip.hover title="Copy endpoint URL"
                  @click="$_copyToClipboard('https://' + endpoint.value)">
          <b-icon icon="clipboard"></b-icon>
        </b-button>
        <span v-if="endpoint.origins.includes('__blocked.rpcpool.com')" class="ml-2"
              v-b-tooltip.hover
              title="This endpoint is blocked and will reject all requests">
          <b-icon icon="shield-lock-fill"></b-icon>
        </span>
        <span
          v-if="endpoint.origins.length === 0"
          class="ml-2"
          v-b-tooltip.hover
          title="This endpoint is open and will accept requests from any origin"
        >
          <b-icon icon="exclamation-triangle"/>
        </span>
      </div>
      <div v-if="endpoint.origins" class="text-muted">
        <span class="origins-text fs--1">({{ endpoint.origins }})</span>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.btn.clipboard-btn {
  padding: 5px;
  color: #5e6e82;
  border: none;
  background-color: inherit;
}

.origins-text {
  display: inline-block;
  line-height: 1.4;
}
</style>

<script>
import copyToClipboard from '@/mixins/copy-to-clipboard';
import rpcFormattedEndpoints from '@/mixins/rpc-formatted-endpoints';

export default {
  name: 'rpcEndpoints',

  mixins: [rpcFormattedEndpoints, copyToClipboard],

  props: {
    subscriptions: {type: Array, default: () => []},
  },

  data() {
    return {
      tokensPaginationData: [],
      currentPage: 1,
      perPage: 8,
      rpcExpanded: true,
    }
  },

  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },

    endIndex() {
      return this.startIndex + this.perPage;
    },

    hasActiveEndpoints() {
      return this.activeEndpoints.length > 0
    },

    activeEndpoints() {
      return this.collectActiveEndpoints(this.subscriptions);
    },
  },

}
</script>
