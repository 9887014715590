<template>
  <b-modal
    centered
    id="add-user-to-account"
    class="p-3"
    :hide-footer="true"
    size="lg"
    header-bg-variant="light"
    header-class="py-3"
    @shown="fetchUsers"
    @hidden="unsetUsers"
  >
    <template #modal-title>
      <h4>Add user to account</h4>
    </template>

    <div class="mb-2">
      <b-form v-on:submit.prevent="searchUsers">
        <div class="form-group">
          <b-input
            v-model="searchUsersTerm"
            placeholder="Search by username"
            type="search"
          ></b-input>
        </div>
      </b-form>
    </div>

    <div class="mb-2">
      <b-link v-b-modal.create-new-user>Create new user</b-link>
      <create-new-user-modal v-on:user-created="onUserCreated" />
    </div>

    <div v-if="isLoadingUsers">
      <div class="text-center mb-3">
        <b-spinner class="align-middle mr-3"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </div>
    <table v-else class="table table-hover table-sm">
      <thead class="border-bottom">
        <tr>
          <th scope="col">Username</th>
          <th scope="col">Email</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="align-middle" v-for="user in users" :key="user.uuid">
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>
            <span v-if="user.is_admin" class="badge badge-soft-primary">
              Admin
            </span>
          </td>
          <td class='text-center'>
            <b-link v-if="userHasAccount(user)" @click="removeUserFromAccount(user)">
              <span v-if="addRemoveStatus[user.uuid] === 'loading'">
                <b-spinner small></b-spinner>
                <span class="sr-only">Loading...</span>
              </span>
              <span v-else>
                Remove
              </span>
            </b-link>
            <b-link v-else @click="addUserToAccount(user)">
              <span v-if="addRemoveStatus[user.uuid] === 'loading'">
                <b-spinner small></b-spinner>
                <span class="sr-only">Loading...</span>
              </span>
              <span v-else>
                Add
              </span>
            </b-link>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="overflow-auto">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      />
    </div>

    <p class="fs--1">{{ totalRows }} Users</p>

    <div class="modal-footer">
      <b-button variant="secondary" @click="closeAddUserToAccountModal">
        Done
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import http from '@/services/http';
import debounce from 'lodash.debounce';

export default {
  name: 'AddUsersToAccountModal',

  props: ['account'],

  data() {
    return {
      users: [],
      searchUsersTerm: null,
      currentPage: 1,
      perPage: 25,
      totalRows: null,
      addRemoveStatus: {},
      isLoadingUsers: false,
    }
  },

  watch: {
    searchUsersTerm: 'debouncedSearchUsers',
    currentPage: 'fetchUsers'
  },

  methods: {
    async onUserCreated() {
      await this.fetchUsers();
    },

    async fetchUsers() {
      try {
        this.isLoadingUsers = true;
        const response = await http.get(`users?per=${this.perPage}&page=${this.currentPage}`);
        this.users = response.data.users;
        this.totalRows = response.data.meta.total_count;
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      }

      finally {
        this.isLoadingUsers = false;
      }
    },

    unsetUsers() {
      this.users = [];
      this.currentPage = 1;
      this.searchUsersTerm = null;
      this.totalRows = null;
    },

    closeAddUserToAccountModal() {
      this.$bvModal.hide('add-user-to-account');
      this.currentPage = 1;
      this.searchUsersTerm = null;
    },

    debouncedSearchUsers: debounce(function () {
      this.searchUsers();
    }, 600),

    async searchUsers() {
      this.currentPage = 1;
      const response = await http.get(`users?per=${this.perPage}&username=${this.searchUsersTerm}`);
      this.users = response.data.users;
    },

    userHasAccount(user) {
      return user.accounts.map(a => a.uuid).includes(this.account.uuid);
    },

    async addUserToAccount(user) {
      try {
        this.$set(this.addRemoveStatus, user.uuid, 'loading')

        await http.post('admin/accounts_users', {
          accounts_user: {
            account_uuid: this.account.uuid,
            user_uuid: user.uuid
          }
        });

        this.$emit('accounts-users-updated');
        this.users.find(u => u.uuid === user.uuid).accounts.push(this.account);
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      }

      finally {
        this.$set(this.addRemoveStatus, user.uuid, null)
      }
    },

    async removeUserFromAccount(user) {
      try {
        this.$set(this.addRemoveStatus, user.uuid, 'loading')

        await http.delete(`admin/accounts_users?account_uuid=${this.account.uuid}&user_uuid=${user.uuid}`);
        this.$emit('accounts-users-updated');
        user.accounts = user.accounts.filter(a => a.uuid !== this.account.uuid);
      }

      catch (error) {
        console.log(error);
        this.$bvToast.toast(`There was an error submitting your request. Please try again later`, {
          title: 'Error',
          variant: 'danger'
        });
      }

      finally {
        this.$set(this.addRemoveStatus, user.uuid, null)
      }
    },
  }
}
</script>
