const metricsConfigs = {
  // first element is the function name to call in the api
  // second element is the options to pass to echarts to render the chart

  'rps': [
    'fetchRpcRequests', 'rpcRequestOption'
  ],

  'rps-by-origin': [
    'fetchRpcRequestsByOrigin', 'rpcRequestByOriginOption'
  ],

  'rps-by-method': [
    'fetchRpcRequestsByMethod', 'requestsByMethodOption'
  ],

  'rps-by-status': [
    'fetchRpcRequestsByStatus', 'rpcRequestByStatusOption'
  ],

  'das-by-method': [
    'fetchDasByMethod', 'dasRequestsByMethodOption'
  ],

  'traffic-in-mbits': [
    'fetchTrafficInMbits', 'trafficInMbitsOption'
  ],

  'traffic-by-method': [
    'fetchTrafficByMethod', 'trafficByMethodOption'
  ],

  'response-time-by-method': [
    'fetchResponseTimeByMethod', 'responseTimeOption'
  ],

  'response-time-by-host': [
    'fetchResponseTimeByHost', 'responseTimeByHostOption'
  ],

  'egress-bytes': [
    'fetchEgressBytes', 'egressBytesOption'
  ],

  // marketplace specific

  'landed-vs-not-landed': [
    'fetchLandedVsNotLanded', 'landedVsNotLandedOption'
  ],

  'total-received-transactions': [
    'fetchTotalReceivedTransactions', 'totalReceivedTransactions'
  ],

  'transaction-optimisation-preflight': [
    'fetchTransactionOptimisationPreflight', 'transactionOptimisationPreflight'
  ],

  'priority-fees-for-landed': [
    'fetchPriorityFeesForLanded', 'priorityFeesForLandedOption'
  ],

  'compute-budget-for-landed': [
    'fetchComputeBudgetForLanded', 'priorityFeesForLandedOption', {title: {text: 'Compute budget for landed (Global)'}}
  ],

  'transaction-optimisation-priority-fees': [
    'fetchTransactionOptimisationPriorityFees', 'transactionOptimisationPriorityFees'
  ],

  'transaction-optimisation-priority-fees-max-retries': [
    'fetchTransactionOptimisationPriorityFeesMaxRetries', 'transactionOptimisationPriorityFeesMaxRetries'
  ],

  'transaction-optimisation-priority-fees-submitted': [
    'fetchTransactionOptimisationPriorityFeesSubmitted', 'transactionOptimisationPriorityFeesSubmitted'
  ],

  'transaction-optimisation-priority-fees-landed': [
    'fetchTransactionOptimisationPriorityFeesLanded', 'transactionOptimisationPriorityFeesLanded'
  ],

  'transaction-optimisation-compute-budget-submitted': [
    'fetchTransactionOptimisationComputeBudgetSubmitted', 'transactionOptimisationComputeBudgetSubmitted'
  ],

  'transaction-optimisation-compute-budget-landed': [
    'fetchTransactionOptimisationComputeBudgetLanded', 'transactionOptimisationComputeBudgetLanded'
  ],

  'transaction-optimisation-priority-fees-pool-50': [
    'fetchTransactionOptimisationPriorityFeesPool', 'transactionOptimisationPriorityFeesPool50'
  ],

  'transaction-optimisation-priority-fees-pool-90': [
    'fetchTransactionOptimisationPriorityFeesPool', 'transactionOptimisationPriorityFeesPool90'
  ],

  'transaction-optimisation-priority-fees-global-50': [
    'fetchTransactionOptimisationPriorityFeesGlobal', 'transactionOptimisationPriorityFeesGlobal50'
  ],

  'transaction-optimisation-priority-fees-global-90': [
    'fetchTransactionOptimisationPriorityFeesGlobal', 'transactionOptimisationPriorityFeesGlobal90'
  ],
}

export {metricsConfigs};
