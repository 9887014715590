<template>
  <div class="v-chart mb-4">

    <!-- we use loading to see if we can try to render -->
    <div v-if="!isLoading">
      <template v-if="error">
        <div class="v-chart-heading">{{ humanizeChartName(chartName) }}</div>
        <div class="text-danger fs--1">
          {{ error.message }}
        </div>
      </template>

      <template v-else-if="hasData">
        <v-chart
          :option="optionsForChart"
          autoresize
          :style="'height: ' + (height ? height : '425px') + ';'"
          ref="chart"
          @update:legendState="updateLegendState"
          @legendselectchanged="handleLegendSelectChanged"
        />
      </template>
    </div>
  </div>
</template>

<script>
import VueECharts from 'vue-echarts';
import {metricsConfigs} from '@/constants/metrics';

// todo: import less than full library
// https://github.com/ecomfe/vue-echarts#example
// https://vue-echarts.dev/#codegen
import 'echarts';

export default {
  components: {
    'v-chart': VueECharts
  },

  props: {
    chartId: {
      type: String,
      default: null // Optional unique identifier for this chart instance
    },
    chartName: {
      type: String,
      required: true // Used to look up in metricsConfigs
    },
    metricParams: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      required: true
    },
    height: String
  },

  data() {
    return {
      chartData: null,
      optionsForChart: null,
      legendState: {},
      error: null,
      isLoading: true,
      hasData: false
    };
  },

  created() {
    this.fetchChartData();

    // Add a timeout to ensure status is reported even if there's an issue
    setTimeout(() => {
      if (this.isLoading) {
        this.isLoading = false;
        this.$emit('data-status', {
          chartId: this.chartIdentifier,
          hasData: false,
          error: false
        });
      }
    }, 10000); // 10 seconds timeout
  },

  computed: {
    chartOptionsName() {
      return metricsConfigs[this.chartName][1];
    },

    optionsOverrides() {
      return metricsConfigs[this.chartName][2];
    },

    // Use chartId if provided, otherwise use chartName
    chartIdentifier() {
      return this.chartId || this.chartName;
    }
  },

  watch: {
    options: {
      handler(newOptions, oldOptions) {
        this.fetchChartData();
      },
      deep: true
    }
  },

  methods: {
    async fetchChartData() {
      try {
        this.isLoading = true;
        this.error = null;
        this.hasData = false;

        const {metricsConfigs} = await import('@/constants/metrics');

        if (!metricsConfigs[this.chartName]) {
          // console.error(`No configuration found for chart: ${this.chartName}`);
          throw new Error(`No configuration found for chart: ${this.chartName}`);
        }

        const [fetchMethodName] = metricsConfigs[this.chartName];

        const module = await import('@/services/metrics');
        const fetchMethod = module[fetchMethodName];

        if (!fetchMethod) {
          // console.error(`Fetch method not found: ${fetchMethodName}`);
          throw new Error(`Fetch method not found: ${fetchMethodName}`);
        }

        // Prepare params
        const params = {
          ...this.options,
          ...this.metricParams
        };

        // Fetch the data
        this.chartData = await fetchMethod(params);

        // First check if we have valid data for regular charts
        const hasRegularData = Array.isArray(this.chartData) &&
          this.chartData.length > 0 &&
          this.chartData.some(item =>
            item &&
            item.values &&
            Array.isArray(item.values) &&
            item.values.length > 0
          );

        // Initialize chart to get optionsForChart
        await this.initializeChart();

        // For heatmap charts, check if the series data is not empty
        if (this.optionsForChart &&
          this.optionsForChart.series &&
          this.optionsForChart.series[0] &&
          this.optionsForChart.series[0].type === 'heatmap') {
          // For heatmap charts, check if we have valid data in the series
          this.hasData = this.optionsForChart.series[0].data &&
            this.optionsForChart.series[0].data.length > 0 &&
            // Make sure xAxis and yAxis data are not just placeholder values
            !(this.optionsForChart.xAxis.data.length === 1 && this.optionsForChart.xAxis.data[0] === 'No Data') &&
            !(this.optionsForChart.yAxis.data.length === 1 && this.optionsForChart.yAxis.data[0] === 'No Data');
        } else {
          // For regular charts, use the previously calculated value
          this.hasData = hasRegularData;
        }

        // Emit status to parent with chartIdentifier
        this.$emit('data-status', {
          chartId: this.chartIdentifier,
          hasData: this.hasData,
          error: false
        });
      } catch (error) {
        this.error = {
          message: error.message || "There was an issue fetching this chart's data. Please try again later."
        };
        this.$emit('fetch-error', error);
        this.$emit('data-status', {
          chartId: this.chartIdentifier,
          hasData: false,
          error: true
        });
      } finally {
        this.isLoading = false;
      }
    },

    async initializeChart() {
      try {
        const module = await import('@/services/metrics');
        const chartOptions = module[this.chartOptionsName];

        this.optionsForChart = chartOptions(this.chartData, this.optionsOverrides, this.metricParams?.quantile);

        this.$nextTick(() => {
          // Apply legend state
          this.applyLegendState();

          // init the legend state
          if (this.optionsForChart.hasLegendClick) {
            this.initializeLegendState();
          }

          // check if the chart needs a font resize
          if (this.optionsForChart.hasTextResize) {
            this.$nextTick(() => {
              this.updateChartFontSize();
            });
          }
        });
      } catch (error) {
        this.error = {
          message: error.message || "There was an issue initializing the chart. Please try again later."
        };
        this.$emit('fetch-error', error);
      }
    },

    initializeLegendState() {
      if (this.optionsForChart && this.optionsForChart.series) {
        this.legendState = this.optionsForChart.series.reduce((obj, item) => ({
          ...obj,
          [item.name]: this.legendState[item.name] !== undefined ? this.legendState[item.name] : true
        }), {});
        this.updateChartLegend();
      }
    },

    handleLegendSelectChanged(params) {
      if (this.optionsForChart.hasLegendClick) {
        const {name} = params;
        const legendData = this.optionsForChart.legend.data;

        // Check if the clicked item is currently the only one selected
        const isOnlyOneSelected = Object.values(this.legendState).filter(Boolean).length === 1;
        const isClickedItemSelected = this.legendState[name];

        if (isClickedItemSelected && isOnlyOneSelected) {
          // If clicking the only selected item, turn all on
          legendData.forEach(item => {
            this.legendState[item] = true;
          });
        } else {
          // Otherwise, set only the clicked item to true
          legendData.forEach(item => {
            this.legendState[item] = (item === name);
          });
        }

        this.updateChartLegend();
      }
    },

    updateChartLegend() {
      if (this.$refs.chart) {
        this.$refs.chart.setOption({
          legend: {selected: this.legendState}
        });
      }
    },

    updateChartFontSize() {
      const widthFactor = 4;
      if (this.$refs.chart) {
        const chart = this.$refs.chart;
        const width = chart.getWidth();
        const newFontSize = Math.round(width / widthFactor);
        chart.setOption({
          graphic: this.optionsForChart.graphic.map(group => ({
            ...group,
            children: group.children.map(child => {
              if (child.type === 'text' && child.resizable) {
                return {
                  ...child,
                  style: {
                    ...child.style,
                    fontSize: newFontSize
                  }
                };
              }
              return child;
            })
          }))
        });
      }
    },

    humanizeChartName(kebabCase) {
      return kebabCase
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    applyLegendState() {
      if (this.optionsForChart && this.optionsForChart.legend) {
        this.optionsForChart.legend.selected = this.legendState;
      }
    },

    updateLegendState() {
      if (this.optionsForChart && this.optionsForChart.series) {
        let newLegendState = {};
        newLegendState = this.optionsForChart.series.reduce((obj, item) => ({
          ...obj,
          [item.name]: this.legendState[item.name] !== undefined ? this.legendState[item.name] : true
        }), {});
        this.legendState = newLegendState;
        this.$emit('update:legendState', newLegendState);
      }
    }
  }
};
</script>

<style scoped>
.v-chart .tooltip-circle {
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.v-chart-heading {
  font-weight: bold !important;
  font: 17px sans-serif;
  color: #464646;
}
</style>
