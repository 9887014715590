<template>
  <div class="chat-widget">
    <button
      class="chat-toggle-button"
      @click="toggleChatWidget"
      :class="{ 'is-open': isOpen }"
    >
      <b-icon :icon="isOpen ? 'x' : 'chat-dots-fill'" font-scale="1.5"></b-icon>
      <span v-if="unreadCount > 0" class="unread-badge">{{ unreadCount }}</span>
      Chat
    </button>

    <!-- Chat Window -->
    <transition name="slide">
      <div v-if="isOpen" class="chat-window" :class="{ 'maximized': isMaximized }">
        <b-card no-body>
          <b-card-header header-bg-variant="light" class="py-3">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h4 class="mb-0 mr-2">Chat</h4>
                <b-badge v-if="isConnected" variant="success">Connected</b-badge>
                <b-badge v-else variant="danger">Disconnected</b-badge>
              </div>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="toggleMaximize"
                v-b-tooltip.hover
                :title="isMaximized ? 'Minimize' : 'Maximize'"
                data-cy="toggle-maximize-button"
                class="d-none d-sm-block"
              >
                <b-icon :icon="isMaximized ? 'arrows-angle-contract' : 'arrows-angle-expand'"></b-icon>
              </b-button>
            </div>

            <div v-if="accounts.length > 1" class="mt-2">
              <b-nav tabs>
                <template v-for="account in accounts">
                  <b-nav-item
                    :key="account.uuid"
                    :active="account.uuid === selectedAccountUuid"
                    @click="switchAccount(account.uuid)"
                  >
                    {{ account.name }}
                  </b-nav-item>
                </template>
              </b-nav>
            </div>
          </b-card-header>

          <transition name="account-switch" mode="out-in" @after-enter="handleTransitionComplete">
            <div :key="selectedAccountUuid" class="chat-messages" ref="chatBox" @scroll="handleScroll">
              <div v-if="isLoading" class="loading-container">
                <b-spinner variant="primary" label="Loading..."></b-spinner>
                <p class="mt-2 text-muted">Connecting to chat...</p>
              </div>
              <div v-if="isLoadingOlderMessages" class="loading-older-messages">
                <b-spinner small variant="primary" label="Loading..."></b-spinner>
                <span class="ml-2">Loading older messages...</span>
              </div>
              <b-list-group v-else flush>
                <template v-for="group in groupedMessages">
                  <div :key="group.date.getTime()" class="date-separator">
                    <hr>
                    <span class="date-text">{{ formatDate(group.date) }}</span>
                  </div>
                  <b-list-group-item
                    v-for="message in group.messages"
                    :key="message.id"
                    :class="{'text-right': message.isUser}"
                  >
                    <div :class="{'user-message': message.isUser, 'agent-message': !message.isUser}">
                      <strong>{{ message.isUser ? 'You' : formatUsername(message.senderName, message.isTritonEmployee) }}</strong>
                      <p class="mb-0 fs-0" style="white-space: pre-line;">{{ message.text }}</p>
                      <div v-if="message.fileIds && message.fileIds.length" class="message-attachments">
                        <b-img
                          v-for="fileId in message.fileIds"
                          :key="fileId"
                          :src="filePreviews[fileId] || ''"
                          class="message-image"
                          @click="openImagePreview(fileId)"
                        />
                      </div>
                      <small class="text-muted">{{ formatTime(message.timestamp) }}</small>
                    </div>
                  </b-list-group-item>
                </template>
              </b-list-group>
            </div>
          </transition>

          <b-card-footer>
            <b-form @submit.prevent="sendMessage">
              <template v-if="!hasActiveChatWithSelectedAccount">
                <b-form-textarea
                  v-model="newMessage"
                  placeholder="Please describe the issue that you'd like us to help with."
                  :disabled="isLoading"
                  rows="3"
                  max-rows="6"
                  class="mb-2"
                  autocomplete="off"
                />
                <div class="mb-2">
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="!newMessage || isLoading"
                  >
                    Start Chat
                  </b-button>
                </div>
              </template>
              <template v-else>
                <div>
                  <b-input-group class="mb-2">
                    <b-form-textarea
                      v-model="newMessage"
                      placeholder="Type your message..."
                      :disabled="!isConnected || isLoading"
                      autocomplete="off"
                      rows="1"
                      max-rows="4"
                      class="chat-input"
                      no-resize
                      @keydown.enter.prevent="handleEnterKey"
                    />
                    <b-input-group-append>
                      <b-button type="submit" size="sm" variant="primary" :disabled="!isConnected || (!newMessage && !selectedFile) || isLoading">
                        Send
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </template>
            </b-form>

            <input
              type="file"
              ref="fileInput"
              class="d-none"
              accept="image/*"
              @change="handleFileSelect"
            >
            <div v-if="selectedFile" class="selected-file d-flex align-items-center">
              <b-img :src="filePreview" width="50" height="50" class="mr-2"></b-img>
              <span class="mr-2">{{ selectedFile.name }}</span>
              <b-button
                size="sm"
                variant="outline-danger"
                @click="clearSelectedFile"
              >
                <b-icon icon="x"></b-icon>
              </b-button>
            </div>
            <b-progress
              v-if="uploadProgress > 0 && uploadProgress < 100"
              :value="uploadProgress"
              class="mt-2"
            />

            <div>
              <b-link
                variant="outline-secondary"
                @click="$refs.fileInput.click()"
                :disabled="!isConnected || isLoading"
                v-b-tooltip.hover title="Upload image"
                class="text-muted"
              >
                <b-icon scale="1.25" icon="paperclip" aria-label="Upload image"></b-icon>
              </b-link>
            </div>
          </b-card-footer>

          <div v-if="hasActiveChatWithSelectedAccount" class="text-center pt-2 pb-1">
            <b-link @click="resolveAndCloseChat">Mark chat resolved</b-link>
          </div>
        </b-card>
      </div>
    </transition>

    <b-modal
      v-model="showImagePreview"
      hide-footer
      hide-header
      centered
      body-class="p-0"
      content-class="image-preview-modal"
      dialog-class="image-preview-dialog"
      body-bg-variant="transparent"
    >
      <div class="image-preview-container">
        <b-img
          :src="previewImageUrl"
          v-if="previewImageUrl"
          class="preview-image"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import http from '@/services/http';
import MattermostWebSocketClient from '@/services/mattermost_web_socket_client';
import events from '@/services/events';

export default {
  name: 'CustomerServiceChat',

  data() {
    return {
      messages: [],
      newMessage: '',
      isConnected: false,
      websocket: null,
      mattermostUserId: null,
      token: null,
      isOpen: false,
      isMaximized: false,
      unreadCount: 0,
      isScrolledToBottom: true,
      isInitialized: false,
      isLoading: false,
      selectedFile: null,
      filePreview: null,
      uploadProgress: 0,
      showImagePreview: false,
      previewImageUrl: null,
      filePreviews: {},
      selectedAccountUuid: '',
      initializedAccountUuids: new Set(),
      oldestPostId: null,
      isLoadingOlderMessages: false,
      lastScrollTop: 0, // Track last scroll position to determine direction
      messageTypesToIgnore: [
        'system_join_channel',
        'system_add_to_channel',
        'system_change_chan_privacy',
      ]
    }
  },

  mounted() {
    if (this.accounts.length > 0) {
      const firstAccountWithActiveChat = this.accounts.find(acc => acc.has_active_mattermost_chat);

      if (firstAccountWithActiveChat) {
        this.selectedAccountUuid = firstAccountWithActiveChat.uuid;
        this.initializeChatForAccount(this.selectedAccountUuid).then(() => {
          this.setupWebSocket();
        });
      } else {
        this.selectedAccountUuid = this.accounts[0].uuid;
      }
    }

    // Add event listener for Escape key to close the chat
    document.addEventListener('keydown', this.handleKeyDown);

    // Listen for chat resolution events from other users/admins
    this.setupChatResolvedEventListeners();
  },

  beforeDestroy() {
    if (this.websocket) {
      this.websocket.disconnect();
    }

    // Remove event listener when component is destroyed
    document.removeEventListener('keydown', this.handleKeyDown);

    // Remove chat resolved event listeners
    this.cleanupChatResolvedEventListeners();
  },

  computed: {
    ...mapGetters('sessions', ['currentUser']),

    groupedMessages() {
      if (!this.messages.length) return [];

      // Group messages by date
      const groups = this.messages.reduce((acc, message) => {
        const date = new Date(message.timestamp);
        const dateStr = date.toDateString();

        if (!acc[dateStr]) {
          acc[dateStr] = {
            date: date,
            messages: []
          };
        }

        acc[dateStr].messages.push(message);
        return acc;
      }, {});

      // Convert to array and sort by date
      return Object.values(groups).sort((a, b) => a.date - b.date);
    },

    accounts() {
      return this.currentUser.accounts;
    },

    selectedAccount() {
      return this.accounts.find(acc => acc.uuid === this.selectedAccountUuid);
    },

    channelId() {
      return this.selectedAccount.mattermost_channel_guid;
    },

    hasActiveChatWithSelectedAccount() {
      return this.selectedAccount.has_active_mattermost_chat;
    },

    mattermostHostname() {
      if (window.location.hostname === 'localhost') {
        return 'localhost:8065';
      } else if (window.location.hostname === 'customers-staging1.rpcpool.com') {
        return 'mattermost-staging.rpcpool.com';
      } else {
        return 'mattermost.triton.one';
      }
    },

    mattermostHttpUrl() {
      if (window.location.hostname === 'localhost') {
        return 'http://' + this.mattermostHostname + '/api/v4';
      } else {
        return 'https://' + this.mattermostHostname + '/api/v4';
      }
    },
  },

  methods: {
    ...mapMutations('sessions', ['UPDATE_CURRENT_USER_ACCOUNT_CHAT', 'UPDATE_CURRENT_USER_ACCOUNT']),

    handleKeyDown(event) {
      // Close chat or image preview when Escape key is pressed
      if (event.key === 'Escape' && this.isOpen) {
        if (this.previewImageUrl) {
          this.showImagePreview = false;
          this.previewImageUrl = null;
        } else {
          this.isOpen = false;
        }
      }
    },

    async switchAccount(accountUuid) {
      if (this.selectedAccountUuid === accountUuid) return;

      this.selectedAccountUuid = accountUuid;
      this.messages = [];
      this.isInitialized = false;

      if (this.isOpen) {
        this.isLoading = true;
        try {
          await this.initializeChatForAccount(accountUuid);
          await this.loadExistingMessages();
          this.isInitialized = true;

          this.$nextTick(() => {
            this.scrollToBottom();
          });
        } catch (error) {
          console.error('Failed to initialize chat for new account:', error);
          this.$bvToast.toast('Failed to switch accounts. Please try again.', {
            title: 'Error',
            variant: 'danger'
          });
        } finally {
          this.isLoading = false;
        }
      }
    },

    formatUsername(username, isTritonEmployee) {
      return isTritonEmployee ? `${username} (Triton)` : username;
    },

    handleFileSelect(event) {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.startsWith('image/')) {
        this.$bvToast.toast('Please select an image file', {
          title: 'Invalid File Type',
          variant: 'danger'
        });
        return;
      }

      if (file.size > 50 * 1024 * 1024) { // 50MB limit
        this.$bvToast.toast('File size must be less than 50MB', {
          title: 'File Too Large',
          variant: 'danger'
        });
        return;
      }

      this.selectedFile = file;
      this.createFilePreview(file);
    },

    createFilePreview(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.filePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    clearSelectedFile() {
      this.selectedFile = null;
      this.filePreview = null;
      this.uploadProgress = 0;
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
    },

    async getFilePreviewBlob(fileId) {
      try {
        const response = await fetch(`${this.mattermostHttpUrl}/files/${fileId}/preview`, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch image preview');
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      } catch (error) {
        console.error('Failed to load image preview:', error);
        return '';
      }
    },

    async loadFilePreviews(message) {
      for (const fileId of message.fileIds) {
        if (!this.filePreviews[fileId]) {
          const previewUrl = await this.getFilePreviewBlob(fileId);
          this.$set(this.filePreviews, fileId, previewUrl);
        }
      }
    },

    async openImagePreview(fileId) {
      try {
        const response = await fetch(`${this.mattermostHttpUrl}/files/${fileId}`, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch full image');
        const blob = await response.blob();
        this.previewImageUrl = URL.createObjectURL(blob);
        this.showImagePreview = true;
      } catch (error) {
        console.error('Failed to load full image:', error);
        this.$bvToast.toast('Failed to load image', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },

    async resolveAndCloseChat() {
      try {
        await http.post(`mattermost_chats/accounts/${this.selectedAccountUuid}/resolve_chat`);
        this.UPDATE_CURRENT_USER_ACCOUNT_CHAT({
          accountUuid: this.selectedAccountUuid,
          mattermostChatState: false
        });
        this.isOpen = false;
        this.$bvToast.toast('Chat resolved and closed successfully', {
          title: 'Success',
          variant: 'default'
        });
      } catch (error) {
        console.error('Failed to resolve chat:', error);
        this.$bvToast.toast('Failed to resolve chat. Please try again.', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },

    async toggleChatWidget() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.unreadCount = 0;
        if (!this.isInitialized) {
          this.isLoading = true;
          try {
            await this.initializeChatForAccount(this.selectedAccountUuid);
            if (!this.websocket) { this.setupWebSocket(); }
            await this.loadExistingMessages();
            this.isInitialized = true;
          } catch (error) {
            console.error('Failed to initialize chat:', error);
            this.$bvToast.toast('Failed to initialize chat. Please try again.', {
              title: 'Error',
              variant: 'danger'
            });
            this.isOpen = false;
            return;
          } finally {
            this.isLoading = false;
          }
        }
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },

    handleScroll() {
      const chatBox = this.$refs.chatBox;
      const threshold = 2; // 2px threshold
      const currentScrollTop = chatBox.scrollTop;

      // Check if scrolled to bottom
      const bottomPosition = chatBox.scrollHeight - currentScrollTop;
      this.isScrolledToBottom = Math.abs(bottomPosition - chatBox.clientHeight) <= threshold;

      // Check if scrolled to top AND scrolling upward (to load older messages)
      // Only load older messages when scrolling up, not when scrolling back down
      const isScrollingUp = currentScrollTop < this.lastScrollTop;
      if (currentScrollTop < 50 && isScrollingUp && !this.isLoadingOlderMessages && this.oldestPostId) {
        this.loadOlderMessages();
      }

      // Update last scroll position
      this.lastScrollTop = currentScrollTop;
    },

    async initializeChatForAccount(accountUuid) {
      if (this.initializedAccountUuids.has(accountUuid)) {
        return;
      } else {
        try {
          const response = await http.post('mattermost_chats/init_chat', {
            account_uuid: accountUuid
          });
          const respData = response.data;
          this.token = respData.token;
          this.mattermostUserId = respData.user.id;

          if (this.selectedAccount.mattermost_channel_guid === null) {
            this.UPDATE_CURRENT_USER_ACCOUNT({
              account_uuid: accountUuid,
              mattermost_channel_guid: respData.channel.id
            });
          }

          this.initializedAccountUuids.add(accountUuid);
        } catch (error) {
          console.error('Chat initialization failed:', error);
          this.$bvToast.toast('Failed to connect to customer service. Please try again later.', {
            title: 'Connection Error',
            variant: 'danger'
          });
          throw error;
        }
      }
    },

    setupWebSocket() {
      const client = new MattermostWebSocketClient(this.mattermostHostname, this.token);

      client.addEventListener('message', async ({ post, senderName }) => {

        // This is not going to work - we should instead preserve a list of messages per channel/account

        if (post.user_id !== this.mattermostUserId && post.channel_id === this.channelId) {
          const userMap = await this.fetchUsers();
          const userInfo = userMap[post.user_id] || {
            username: senderName || 'Unknown User',
            isTritonEmployee: false
          };

          if (post.file_ids && post.file_ids.length) {
            await this.loadFilePreviews({ fileIds: post.file_ids });
          }

          // .filter(post => !this.messageTypesToIgnore.includes(post.type));

          if (!this.messageTypesToIgnore.includes(post.type)) {
            this.messages.push({
              id: post.id,
              text: post.message,
              fileIds: post.file_ids,
              isUser: false,
              senderName: userInfo.username,
              isTritonEmployee: userInfo.isTritonEmployee,
              timestamp: new Date(post.create_at)
            });
          }

          if (!this.isOpen || !this.isScrolledToBottom) {
            this.unreadCount++;
          }

          // if the user is already scrolled near the bottom, then scroll down so that they can see the new message
          if (this.isScrolledToBottom) {
            this.scrollToBottom();
          }
        }
      });

      client.initialize();
      this.websocket = client;
      this.isConnected = true;
    },

    async sendMessage() {
      if (!this.newMessage.trim() && !this.selectedFile) return;

      try {
        if (!this.hasActiveChatWithSelectedAccount) {
          await http.post(`mattermost_chats/accounts/${this.selectedAccountUuid}/start_chat`);
          this.UPDATE_CURRENT_USER_ACCOUNT_CHAT({ accountUuid: this.selectedAccountUuid, mattermostChatState: true });
        }

        let fileIds = [];
        if (this.selectedFile) {
          fileIds = await this.uploadFile();
        }

        // Then create the post with the message and file_ids
        const postData = {
          channel_id: this.channelId,
          message: this.newMessage.trim(),
          file_ids: fileIds
        };

        const response = await fetch(`${this.mattermostHttpUrl}/posts`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)
        });

        if (!response.ok) throw new Error('Failed to send message');

        const messageData = await response.json();
        this.messages.push({
          id: messageData.id,
          text: this.newMessage,
          fileIds: messageData.file_ids,
          isUser: true,
          timestamp: new Date()
        });

        if (messageData.file_ids && messageData.file_ids.length) {
          messageData.file_ids.forEach(f => {
            if (!this.filePreviews[f]) {
              this.getFilePreviewBlob(f).then((url) => {
                this.$set(this.filePreviews, f, url);
              });
            }
          })
        }

        this.newMessage = '';
        this.clearSelectedFile();
        this.uploadProgress = 0;
        this.scrollToBottom();
      } catch (error) {
        console.error('Message send failed:', error);
        this.$bvToast.toast('Failed to send message. Please try again.', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },

    async uploadFile() {
      const fileFormData = new FormData();
      fileFormData.append('files', this.selectedFile);
      fileFormData.append('channel_id', this.channelId);

      const fileResponse = await fetch(`${this.mattermostHttpUrl}/files`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.token}`
        },
        body: fileFormData,
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
      });

      if (!fileResponse.ok) throw new Error('Failed to upload file');

      const fileData = await fileResponse.json();
      return fileData.file_infos.map(info => info.id);
    },

    async fetchUsers() {
      try {
        const response = await fetch(`${this.mattermostHttpUrl}/users`, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch users');

        const users = await response.json();
        return users.reduce((acc, user) => {
          acc[user.id] = {
            username: user.username,
            isTritonEmployee: user.props?.is_triton_employee === 'true'
          };
          return acc;
        }, {});
      } catch (error) {
        console.error('Failed to fetch users:', error);
        return {};
      }
    },

    async loadExistingMessages() {
      try {
        const [messagesResponse, userMap] = await Promise.all([
          fetch(`${this.mattermostHttpUrl}/channels/${this.channelId}/posts`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          }),
          this.fetchUsers()
        ]);

        if (!messagesResponse.ok) throw new Error('Failed to load messages');

        const data = await messagesResponse.json();
        const posts = Object.values(data.posts)
          .sort((a, b) => a.create_at - b.create_at)
          .filter(post => !this.messageTypesToIgnore.includes(post.type));

        // Store the oldest post ID if we have posts
        if (posts.length > 0) {
          this.oldestPostId = posts[0].id;
        }

        const formattedPosts = posts.map(post => {
          const userInfo = userMap[post.user_id] || {
            username: post.props?.override_username || 'Unknown User',
            isTritonEmployee: false
          };
          return {
            id: post.id,
            text: post.message,
            fileIds: post.file_ids,
            isUser: post.user_id === this.mattermostUserId,
            senderName: userInfo.username,
            isTritonEmployee: userInfo.isTritonEmployee,
            timestamp: new Date(post.create_at)
          };
        });

        this.messages = formattedPosts;

        // For each message with fileIds, load previews
        for (const message of this.messages) {
          if (message.fileIds && message.fileIds.length) {
            await this.loadFilePreviews(message);
          }
        }

        this.scrollToBottom();
        this.isScrolledToBottom = true;
      } catch (error) {
        console.error('Failed to load messages:', error);
        this.$bvToast.toast('Failed to load message history', {
          title: 'Error',
          variant: 'danger'
        });
      }
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const chatBox = this.$refs.chatBox;

        if (this.isOpen && chatBox) {
          chatBox.scrollTop = chatBox.scrollHeight;
          this.isScrolledToBottom = true;
        }
      });
    },

    formatTime(timestamp) {
      return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },

    formatDate(date) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      if (date.toDateString() === today.toDateString()) {
        return 'Today';
      } else if (date.toDateString() === yesterday.toDateString()) {
        return 'Yesterday';
      } else {
        return date.toLocaleDateString([], {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        });
      }
    },

    toggleMaximize() {
      this.isMaximized = !this.isMaximized;
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },

    handleEnterKey(event) {
      // Allow new lines with Shift+Enter
      if (event.shiftKey) {
        event.preventDefault = false;
        return;
      }

      if (this.newMessage.trim() || this.selectedFile) {
        this.sendMessage();
      }
    },

    setupChatResolvedEventListeners() {
      this.accounts.forEach(account => {
        events.$on(`mattermostChatResolved-${account.uuid}`, this.handleChatResolved);
      });
    },

    cleanupChatResolvedEventListeners() {
      this.accounts.forEach(account => {
        events.$off(`mattermostChatResolved-${account.uuid}`, this.handleChatResolved);
      });
    },

    handleChatResolved(data) {
      // If the resolved chat is the currently selected account and the chat window is open, close it
      if (data.account_uuid === this.selectedAccountUuid && this.isOpen) {
        this.isOpen = false;
        this.$bvToast.toast('Chat has been resolved by an administrator', {
          title: 'Chat Resolved',
          variant: 'default'
        });
      }
    },

    handleTransitionComplete() {
      // This method is called after the account-switch transition is complete
      if (this.isInitialized && !this.isLoading) {
        this.scrollToBottom();
      }
    },

    async loadOlderMessages() {
      if (!this.oldestPostId || this.isLoadingOlderMessages) return;

      this.isLoadingOlderMessages = true;
      const scrollHeightBefore = this.$refs.chatBox.scrollHeight;
      const scrollTopBefore = this.$refs.chatBox.scrollTop;

      try {
        const response = await fetch(
          `${this.mattermostHttpUrl}/channels/${this.channelId}/posts?before=${this.oldestPostId}`,
          {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          }
        );

        if (!response.ok) throw new Error('Failed to load older messages');

        const data = await response.json();
        const userMap = await this.fetchUsers();

        // Process the older posts
        const olderPosts = Object.values(data.posts)
          .sort((a, b) => a.create_at - b.create_at)
          .filter(post => !this.messageTypesToIgnore.includes(post.type));

        // Update the oldest post ID if we got new posts
        if (olderPosts.length > 0) {
          // Check if there are more older messages
          // If prev_post_id is not present or empty, we've reached the beginning of the history
          if (!data.prev_post_id) {
            // No more older messages to load
            this.oldestPostId = null;
            console.log('Reached the beginning of message history');
          } else {
            // More older messages available
            this.oldestPostId = olderPosts[0].id;
          }

          // Format the posts
          const formattedPosts = olderPosts.map(post => {
            const userInfo = userMap[post.user_id] || {
              username: post.props?.override_username || 'Unknown User',
              isTritonEmployee: false
            };
            return {
              id: post.id,
              text: post.message,
              fileIds: post.file_ids,
              isUser: post.user_id === this.mattermostUserId,
              senderName: userInfo.username,
              isTritonEmployee: userInfo.isTritonEmployee,
              timestamp: new Date(post.create_at)
            };
          });

          // Prepend older posts to the messages array
          this.messages = [...formattedPosts, ...this.messages];

          // Load file previews for new messages
          for (const message of formattedPosts) {
            if (message.fileIds && message.fileIds.length) {
              await this.loadFilePreviews(message);
            }
          }

          // Maintain scroll position - use setTimeout to ensure DOM is updated
          setTimeout(() => {
            if (this.$refs.chatBox) {
              const newScrollHeight = this.$refs.chatBox.scrollHeight;
              const heightDifference = newScrollHeight - scrollHeightBefore;

              // Set the scroll position to keep the same messages in view
              this.$refs.chatBox.scrollTop = scrollTopBefore + heightDifference;

              // Reset lastScrollTop to prevent triggering loadOlderMessages again
              this.lastScrollTop = this.$refs.chatBox.scrollTop;

              // Ensure scrolling is enabled
              this.$refs.chatBox.style.overflowY = 'auto';

              // Log for debugging
              console.log('Scroll maintained:', {
                before: scrollTopBefore,
                after: this.$refs.chatBox.scrollTop,
                heightDifference
              });
            }
          }, 50); // Short delay to ensure DOM is updated
        }
      } catch (error) {
        console.error('Failed to load older messages:', error);
        this.$bvToast.toast('Failed to load older messages', {
          title: 'Error',
          variant: 'danger'
        });
      } finally {
        this.isLoadingOlderMessages = false;
      }
    }
  }
}
</script>

<style scoped>
.chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-toggle-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: rgba(0, 123, 255, 0.9);
  color: white;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1001;
}

.chat-toggle-button:hover {
  transform: scale(1.1);
  background-color: rgba(0, 86, 179, 0.95);
}

.chat-toggle-button.is-open {
  background-color: rgba(220, 53, 69, 0.9);
}

.unread-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}

.chat-window {
  position: fixed; /* fixed ensures it stays visible on the viewport */
  bottom: 80px;
  right: 0;
  width: calc(min(550px, 100vw));
  max-height: calc(100vh - 120px); /* Account for bottom spacing and toggle button */
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden; /* Ensure content doesn't overflow rounded corners */
  transition: all 0.3s ease;
}

/* Add styles for maximized state */
.chat-widget:has(.chat-window) .isMaximized {
  width: 80vw;
  height: 80vh;
  max-height: 80vh;
  bottom: 10vh;
  right: 10vw;
}

/* Vue doesn't support :has selector in all browsers, so we'll use a class binding approach */
.chat-window[class*="maximized"] {
  width: 80vw;
  height: 80vh;
  max-height: 80vh;
  bottom: 10vh;
  right: 10vw;
}

/* Make b-card take full height */
.chat-window .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; /* Required for Firefox */
}

/* Allow card body to scroll */
.chat-window .card-body {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0; /* Required for Firefox */
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  min-height: 0; /* Required for Firefox */
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-older-messages {
  text-align: center;
  padding: 10px;
  color: #6c757d;
  font-size: 0.875rem;
}

.user-message {
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-left: 25%;
}

.agent-message {
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-right: 25%;
}

.message-attachments {
  margin-top: 0.5rem;
}

.message-image {
  max-width: 200px;
  max-height: 200px;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 0.25rem;
  transition: transform 0.2s;
}

.message-image:hover {
  transform: scale(1.05);
}

.selected-file {
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
}

/* Account switching animation */
.account-switch-enter-active,
.account-switch-leave-active {
  transition: opacity 0.25s ease, transform 0.25s ease;
}

.account-switch-enter,
.account-switch-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.account-switch-enter-to,
.account-switch-leave {
  opacity: 1;
  transform: translateY(0);
}

/* Slide transition */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.list-group-item {
  border: none;
}

.date-separator {
  position: relative;
  text-align: center;
  margin: 1.5rem 0;
}

.date-separator hr {
  margin: 0;
  border-top: 1px solid #e9ecef;
}

.date-separator .date-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 1rem;
  color: #6c757d;
  font-size: 0.875rem;
  font-weight: 500;
}

.chat-input {
  border-radius: 0.25rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: 38px;
}

.chat-input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: 0;
}

/* Image Preview Modal Styles */
.image-preview-dialog {
  max-width: 90vw !important;
  max-height: 90vh !important;
  width: auto !important;
  margin: 1.75rem auto;
}

.image-preview-modal {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.image-preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.preview-image {
  max-width: 90vw;
  max-height: 85vh;
  object-fit: contain;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

/* Override Bootstrap Vue modal background */
.modal-content {
  background-color: transparent !important;
}

.modal-backdrop {
  opacity: 0.7 !important;
}

/* Deep selectors for scoped styles */
::v-deep .modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

::v-deep .modal-body {
  background-color: transparent !important;
  padding: 0 !important;
}

::v-deep .modal-dialog {
  border: none !important;
  background-color: transparent !important;
}
</style>
